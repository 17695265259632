import React from 'react';
import {
  ContentfulComponentModal,
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  PageTemplateQuery,
} from '../../graphql-types';
import { graphql, PageProps } from 'gatsby';
import { WEBSITE } from '../types/website.enum';
import edgepointTheme from '../themes/edgepoint';
import cymbriaTheme from '../themes/cymbria';
import Layout from '../components/Layout';
import { PageContext } from '../types/pageContext';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import ComponentSelector from '../components/ComponentSelector';
import { useGlobalState } from '../hooks/useGlobalState';
import GlobalSiteModal from '../components/GlobalSiteModal';
import Header from '../components/Header';
import Modal from '../components/Modal';
import CookiePreferences from '../components/CookiePreferences';
import { checkSegmentInURL } from '../utils/urlUtils';

export const query = graphql`
  query PageTemplate(
    $id: String!
    $node_locale: String!
    $globalWebsiteContentfulId: String!
  ) {
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      slug
      seo {
        ...ContentfulComposeSeoFragment
      }
      content {
        ... on ContentfulPageLanding {
          id
          contentful_id
          __typename
          sections {
            ... on ContentfulComponentArticleSet {
              ...ContentfulComponentArticleSetFragment
            }
            ... on ContentfulComponentCalloutCtaSet {
              ...ContentfulComponentCalloutCtaSetFragment
            }
            ... on ContentfulComponentComponentSet {
              ...ContentfulComponentComponentSetFragment
            }
            ... on ContentfulComponentContactUs {
              ...ContentfulComponentContactUsFragment
            }
            ... on ContentfulComponentDataVisualization {
              ...ContentfulComponentDataVisualizationFragment
            }
            ... on ContentfulComponentFlexible {
              ...ContentfulComponentFlexibleFragment
            }
            ... on ContentfulComponentHeroBanner {
              ...ContentfulComponentHeroBannerFragment
            }
            ... on ContentfulComponentMediaCopy {
              ...ContentfulComponentMediaCopyFragment
            }
            ... on ContentfulComponentStatsSet {
              ...ContentfulComponentStatsSetFragment
            }
            ... on ContentfulComponentPortfolioSet {
              ...ContentfulComponentPortfolioSetFragment
            }
            ... on ContentfulComponentTeams {
              ...TeamsFragment
            }
            ... on ContentfulComponentTextBlock {
              ...ContentfulComponentTextBlockFragment
            }
            ... on ContentfulComponentOverviewSet {
              ...ContentfulComponentOverviewSetFragment
            }
            ... on ContentfulComponentList {
              ...ContentfulComponentListFragment
            }
          }
        }
      }
      website {
        ...WebsiteFragment
      }
      pageHeader {
        ...ContentfulComponentSiteHeaderFragment
      }
      pageFooter {
        ...ContentfulComponentSiteFooterFragment
      }
      pageModal {
        ...ContentfulComponentModalFragment
      }
    }
    allContentfulTopicPortfolioSeries(
      filter: {
        node_locale: { eq: $node_locale }
        topic__portfolio: {
          elemMatch: {
            compose__page: {
              elemMatch: {
                website: { contentful_id: { eq: $globalWebsiteContentfulId } }
              }
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          title
          node_locale
          instrumentKey
          inceptionDate
          isGated
          filterGroup
          nonHst
          series {
            id
            title
          }
          topic__portfolio {
            id
            title
            compose__page {
              slug
            }
          }
        }
      }
    }
  }
`;
const Page: React.FC<PageProps<PageTemplateQuery, PageContext>> = (props) => {
  const { data, location } = props;
  const { contentfulComposePage } = data;
  const { site } = useGlobalState();

  // Get any subSite entry pages attached to the current global website and compare to the current url slug
  const subSiteEntryPages = contentfulComposePage?.website?.subsiteEntryPage;

  let currentSubSite;
  if (subSiteEntryPages && subSiteEntryPages.length > 0) {
    subSiteEntryPages.forEach((subSiteEntryPage) => {
      if (checkSegmentInURL(location.href, subSiteEntryPage.slug)) {
        currentSubSite = subSiteEntryPage;
      }
    });
  }

  const contentPageLanding = contentfulComposePage.content;
  const mainContent = contentPageLanding.sections?.map((item, index) => {
    return (
      <ComponentSelector
        key={`component-${item.id}-${index}`}
        contentfulComponent={item}
        subsite={currentSubSite}
      />
    );
  });

  const mobileMenuPaddingClasses = 'pt-m4 lg:pt-0';

  const websiteModal = contentfulComposePage?.website?.modal;

  const cookiePreferences = contentfulComposePage?.website?.cookiePreferences;

  // Page Header & footer setup (if exists) otherwise default to global website header & footer
  const pageHeader =
    (contentfulComposePage?.pageHeader as unknown as ContentfulComponentSiteHeader) ??
    (contentfulComposePage?.website
      ?.header as unknown as ContentfulComponentSiteHeader);

  const pageFooter =
    (contentfulComposePage?.pageFooter as unknown as ContentfulComponentSiteFooter) ??
    (contentfulComposePage?.website
      ?.footer as unknown as ContentfulComponentSiteFooter);

  const pageModal =
    contentfulComposePage?.pageModal as unknown as ContentfulComponentModal;

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={contentfulComposePage?.seo} location={location} />

      <Header
        data={pageHeader}
        location={location}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        mobileLogo={contentfulComposePage?.website?.siteAssets?.mobileLogo}
        subsite={currentSubSite}
      />

      <main id="main" className={`${mobileMenuPaddingClasses}`}>
        {mainContent}
      </main>

      <Footer
        data={pageFooter}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        socialLinks={contentfulComposePage?.website?.siteAssets?.socialLinks}
      />

      {pageModal ? (
        <Modal
          id={pageModal?.id}
          headline={pageModal?.headline}
          body={pageModal?.body}
          disclaimer={pageModal?.disclaimer}
          rememberLabel={pageModal?.rememberLabel}
          declineButton={pageModal?.declineButton}
          acceptButton={pageModal?.acceptButton}
        />
      ) : (
        <>
          {websiteModal?.layout === 'Default' ? (
            <GlobalSiteModal
              id={contentfulComposePage?.website?.modal?.key}
              content={contentfulComposePage?.website?.modal?.richText}
              linkButton={contentfulComposePage?.website?.modal?.links?.[0]}
              image={contentfulComposePage?.website?.modal?.featureImage}
              delay={contentfulComposePage?.website?.modal?.number}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default Page;
