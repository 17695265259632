import { useLocalization } from 'gatsby-theme-i18n';
import React from 'react';
import styled from 'styled-components';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulTopicStat,
} from '../../graphql-types';
import Button from './Button';
import ContentfulImage from './ContentfulImage';
import Divider from './Divider';
import InfoTooltip from './InfoTooltip';
import Typography from './Typography';

const BackgroundContainer = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

interface StatsSetProps {
  backgroundImage?: ContentfulAsset;
  button?: ContentfulComponentCtaButton;
  className?: string;
  columns?: number;
  items?: ContentfulTopicStat[];
  subheading?: string;
  title?: string;
  variant?: string;
}
const StatsSet: React.FC<StatsSetProps> = (props) => {
  const {
    backgroundImage,
    button,
    className,
    columns,
    items,
    subheading,
    title,
    variant,
  } = props;

  const { locale } = useLocalization();

  // determine width of each item to control number of columns in flex
  // we do not use grid to do this as client wants to have items in non full rows be centered
  const itemSpan = `${100 / columns}%`;

  // 'centered' variant produces a title above and a flex of items below
  let statsSetVariant = <></>;
  switch (variant) {
    case 'centered': {
      statsSetVariant = (
        <div className={`${className || ''} py-m3 relative`}>
          {backgroundImage && (
            <BackgroundContainer>
              <ContentfulImage
                image={backgroundImage}
                alt={backgroundImage.description || ''}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </BackgroundContainer>
          )}
          <div className="container relative">
            {title && (
              <Typography
                as="h2"
                variant="h1"
                className="text-center uppercase"
              >
                {title}
              </Typography>
            )}
            {subheading && (
              <Typography as="p" variant="h3" className="text-center">
                {subheading}
              </Typography>
            )}
            {/* centered flex parent */}
            <div
              className={`flex flex-col lg:flex-row mt-m2 lg:justify-center flex-wrap lg:gap-y-[50px]`}
            >
              {/* centered stat items */}
              {items.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`text-center basis-1/${columns} grow-0 shrink-0 mt-s3 lg:mt-0 first:mt-0  box-border lg:w-1/${columns}`}
                  >
                    {item?.title && (
                      <Typography
                        as="h3"
                        variant="s2"
                        className="text-secondary whitespace-nowrap w-11/12 mx-auto"
                      >
                        {item?.type === 'dollar' && locale === 'en' && '$'}
                        {item?.title}
                        {item?.type === 'dollar' && locale === 'fr' && ' $'}
                        {item?.type === 'percent' && '%'}
                        {item?.additionalInfo?.copy && (
                          <InfoTooltip copy={item?.additionalInfo?.copy} />
                        )}
                      </Typography>
                    )}
                    {item?.subheading?.subheading && (
                      <Typography
                        as="p"
                        variant="body"
                        className="mt-s1  w-11/12 mx-auto"
                      >
                        {item?.subheading?.subheading}
                      </Typography>
                    )}
                  </div>
                );
              })}
            </div>
            {button && (
              <Button
                variant={button.variant}
                link={button?.page?.slug || button?.externalUrl}
                openInNewWindow={button?.openInNewWindow}
                className="mt-m2 mx-auto"
              >
                {button.text}
              </Button>
            )}
          </div>
        </div>
      );
      break;
    }
    // 'split' variant produces title to the left and flex of items to the right, but is stacked on mobile
    case 'split': {
      statsSetVariant = (
        <div className={`${className || ''} relative`}>
          {backgroundImage && (
            <BackgroundContainer>
              <ContentfulImage
                image={backgroundImage}
                alt={backgroundImage.description || ''}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </BackgroundContainer>
          )}
          <div className="container relative overflow-hidden py-l2">
            <div className="flex flex-col lg:flex-row">
              <div className="flex-none lg:w-40">
                {title && (
                  <Typography as="h2" variant="h2">
                    {title}
                  </Typography>
                )}
                {subheading && (
                  <Typography as="p" variant="body" className="mt-s2">
                    {subheading}
                  </Typography>
                )}
                {button && (
                  <Button
                    variant={button.variant}
                    link={button?.page?.slug || button?.externalUrl}
                    openInNewWindow={button?.openInNewWindow}
                    className="mt-s3"
                  >
                    {button.text}
                  </Button>
                )}
              </div>
              <div className="flex-none lg:w-10"></div>
              {/* split flex parent */}
              <div className="flex flex-row justify-center flex-wrap lg:gap-y-[48px] w-full lg:w-50 text-left">
                {/* split stat items */}
                {items.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={`mt-m2 lg:mt-0 basis-1/${columns} grow-0 shrink-0 box-border pr-[14px]`}
                      style={{ width: itemSpan }}
                    >
                      {item?.image && (
                        <ContentfulImage
                          image={item?.image}
                          alt={item?.image.description || ''}
                        />
                      )}
                      {item?.title && (
                        <Typography
                          as="div"
                          variant="body"
                          className="mt-s1 font-medium"
                        >
                          {item?.title}
                        </Typography>
                      )}
                      {item?.subheading?.subheading && (
                        <Typography as="p" variant="body" className="mt-s1">
                          {item?.subheading?.subheading}
                        </Typography>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Divider color="primary" />
        </div>
      );
      break;
    }
  }
  return statsSetVariant;
};

export default StatsSet;
