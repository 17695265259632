import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Collapse,
  FormControl,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Close, Menu } from '@material-ui/icons';
import {
  ContentfulAsset,
  ContentfulComponentNavItem,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
} from '../../graphql-types';
import Typography from './Typography';
import Link from './Link';
import ContentfulImage from './ContentfulImage';
import { WEBSITE } from '../types/website.enum';
import Search from './Search';
import { useGlobalState } from '../hooks/useGlobalState';

const LanguageNavigationItem = styled.li`
  &:not(:first-of-type):before {
    content: '|';
    margin-right: ${(props) => props.theme.spacing.s2};
  }
`;

const InstitutionalNavigationItem = styled.li`
  &:first-of-type:after {
    content: '|';
    padding-left: ${(props) => props.theme.spacing.s2};
  }
`;

const MainNavigationItemMenuButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  font: inherit;
`;

const MainNavigationItem = styled.li`
  ${(props) => props.theme.typography.nav}
  ${MainNavigationItemMenuButton}:hover,
  ${MainNavigationItemMenuButton}:active,
  ${MainNavigationItemMenuButton}:focus,
  a:hover,
  a:active,
  a:focus {
    color: ${(props) => props.theme.color.secondary};
    text-decoration: none !important;
  }
  svg {
    margin-left: -4px;
  }
`;

const SubNavigationItem = styled.li`
  ${(props) => props.theme.typography.nav}
`;

const SkipLink = styled.a`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  position: absolute;
  left: calc(50% - 150px);
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  z-index: 150;

  background-color: ${(props) => props.theme.color.white};
  border-right: 2px solid ${(props) => props.theme.color.black};
  border-left: 2px solid ${(props) => props.theme.color.black};
  border-bottom: 2px solid ${(props) => props.theme.color.black};
  color: ${(props) => props.theme.color.black};
  text-decoration: none;
  font-weight: 400;

  &:focus {
    outline: none;
    transform: translateY(0%);
  }
`;

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    border-bottom: none;
    justify-content: start;
    min-height: 0px;
    padding: 0px;
  }
  .MuiAccordionSummary-content {
    flex: initial;
    margin: 0px;
  }
  .MuiIconButton-root {
    padding: 0px;
    marginleft: 8px;
  }
  .MuiAccordionDetails-root {
    padding: 0px 0px 0px 16px;
  }
  .Mui-expanded {
    margin: 0px;
  }
`;

const MobileSisterLanguageNavItem = styled.li`
  align-items: center;
  display: flex;
  margin-right: ${(props) => props.theme.spacing.s3};

  &:first-of-type {
    padding-left: 0px;
  }
  &:not(:first-of-type) {
    &:before {
      content: '|';
      margin-right: ${(props) => props.theme.spacing.s3};
    }
  }
`;

const StyledFormControl = styled(FormControl)<{ dark?: string }>`
  ${(props) => {
    if (props.dark === 'true') {
      return `
      color: ${props.theme.color.white} !important;

      .MuiInput-underline::before {
        border-bottom-color: ${props.theme.color.white} !important;
      }
      .MuiSvgIcon-root {
        color: ${props.theme.color.secondary} !important;
      }
      .MuiInputBase-root,
      .MuiFormLabel-root {
        background-color:red;
        color: ${props.theme.color.white} !important;
      }
    `;
    } else {
      return `
      color: ${props.theme.color.black} !important;

     label + .MuiInput-formControl {
       margin-top: 0.5rem !important
     }

     .MuiSelect-selectMenu {
       font-size: 14px !important;
     }

      &.MuiInput-underline::before {
        border-bottom-color: ${props.theme.color.black} !important;
      }
      & .MuiSvgIcon-root {
        color: ${props.theme.color.secondary} !important;
      }
      `;
    }
  }}
`;

interface HeaderProps {
  location: Location;
  data?: ContentfulComponentSiteHeader;
  logo?: ContentfulAsset;
  mobileLogo?: ContentfulAsset;
  subsite?: ContentfulComposePage;
}
const Header: React.FC<HeaderProps> = (props) => {
  const { data, location, logo, mobileLogo, subsite = undefined } = props;
  const {
    languageNav,
    mainNav,
    sisterSiteNav,
    wealthLinkNav,
    wealthLinkNavTitle,
    institutionalLinkNavTitle,
    institutionalLinkNav,
    logoLink,
  } = data || {};

  const logoLinkUrl = logoLink?.page?.slug ? `/${logoLink?.page?.slug}` : '/';

  const { site } = useGlobalState();
  const { locale, config } = useLocalization();
  const themeContext = React.useContext(ThemeContext);
  const [subMenus, setSubMenus] = React.useState([]);
  const HeaderRef = React.createRef<HTMLDivElement>();

  // sticky nav
  const StickyNavigationRef = React.createRef<HTMLDivElement>();
  const [stickySubMenus, setStickySubMenus] = React.useState([]);
  const previousScrollPosition = React.useRef(0);
  const [showStickyNav, setShowStickyNav] = React.useState(false);
  const [searchPopperAnchorElement, setSearchPopperAnchorElement] =
    React.useState(null);

  const handleScroll = () => {
    const topOfPageBuffer =
      HeaderRef.current?.clientHeight -
      StickyNavigationRef?.current?.clientHeight;
    const currentScrollPosition = window.pageYOffset;

    setShowStickyNav(
      currentScrollPosition > topOfPageBuffer && // client is past header
        previousScrollPosition.current > currentScrollPosition, // client is scrolling back up
    );
    previousScrollPosition.current = currentScrollPosition;
  };

  const handleClickStickySearchButton = (event) => {
    setSearchPopperAnchorElement(
      searchPopperAnchorElement ? null : event.currentTarget,
    );
  };

  const handleClickStickySubMenuButton = (event, id) => {
    const newAnchorEl = [...stickySubMenus];
    newAnchorEl[id] = event.currentTarget;
    setStickySubMenus(newAnchorEl);
  };

  const handleCloseStickySubMenu = (id) => {
    const newAnchorEl = [...stickySubMenus];
    newAnchorEl[id] = null;
    setStickySubMenus(newAnchorEl);
  };

  const handleClickSubMenuButton = (event, id) => {
    const newAnchorEl = [...subMenus];
    newAnchorEl[id] = event.currentTarget;
    setSubMenus(newAnchorEl);
  };

  const handleCloseSubMenu = (id) => {
    const newAnchorEl = [...subMenus];
    newAnchorEl[id] = null;
    setSubMenus(newAnchorEl);
  };

  const createStickyNavItem = (navItem: ContentfulComponentNavItem) => {
    let menuItemElement = <></>;

    // determine if navigation item should be a simple link, or a popup menu
    const hasMenu = navItem.menu && navItem.menu.length > 0;
    if (hasMenu) {
      menuItemElement = (
        <>
          <MainNavigationItemMenuButton
            type="button"
            onClick={(e) => handleClickStickySubMenuButton(e, navItem.id)}
            className="flex items-center cursor-pointer focus:ring-2"
          >
            <span className="mr-s1 uppercase">{navItem.text}</span>
            {stickySubMenus[navItem.id] ? <ArrowDropUp /> : <ArrowDropDown />}
          </MainNavigationItemMenuButton>

          <Popper
            id={`menu-${navItem.id}`}
            anchorEl={stickySubMenus[navItem.id]}
            open={Boolean(stickySubMenus[navItem.id])}
            disablePortal
            placement="bottom-start"
            popperOptions={{
              modifiers: {
                offset: {
                  offset: `-${themeContext.spacing.s2},0`,
                },
              },
            }}
          >
            <ClickAwayListener
              onClickAway={() => handleCloseStickySubMenu(navItem.id)}
            >
              <div
                className={`bg-white border p-s2 ${
                  site === WEBSITE.EDGEPOINT
                    ? 'border-greyLight'
                    : 'border-primary'
                }`}
              >
                <ul>
                  {navItem.menu?.map((subNavItem) => {
                    return (
                      <SubNavigationItem
                        key={`sub-nav-item-${navItem.id}-${subNavItem.id}`}
                        className="mt-s2 first:mt-0"
                      >
                        <Link link={subNavItem}>{subNavItem.text}</Link>
                      </SubNavigationItem>
                    );
                  })}
                </ul>
              </div>
            </ClickAwayListener>
          </Popper>
        </>
      );
    } else {
      // simple navigation item link
      menuItemElement = (
        <Link link={navItem} className="uppercase">
          {navItem.text}
        </Link>
      );
    }

    return (
      <MainNavigationItem
        key={`main-nav-item-${navItem.id}`}
        className={`flex items-center ml-s2 first:ml-0 ${
          // only being on an internal page would require an active classname
          location?.pathname === `/${navItem.page?.slug}` ? 'active' : ''
        }`}
      >
        {menuItemElement}
      </MainNavigationItem>
    );
  };

  const createMainNavItem = (navItem: ContentfulComponentNavItem) => {
    let menuItemElement = <></>;

    // determine if navigation item should be a simple link, or a popup menu
    const hasMenu = navItem.menu && navItem.menu.length > 0;
    if (hasMenu) {
      menuItemElement = (
        <>
          <MainNavigationItemMenuButton
            type="button"
            onClick={(e) => handleClickSubMenuButton(e, navItem.id)}
            className="flex items-center cursor-pointer focus:ring-2"
          >
            <span className="mr-s1 uppercase">{navItem.text}</span>
            {subMenus[navItem.id] ? <ArrowDropUp /> : <ArrowDropDown />}
          </MainNavigationItemMenuButton>

          <Popper
            id={`menu-${navItem.id}`}
            anchorEl={subMenus[navItem.id]}
            open={Boolean(subMenus[navItem.id])}
            disablePortal
            placement="bottom-start"
            popperOptions={{
              modifiers: {
                offset: {
                  offset: `-${themeContext.spacing.s2},0`,
                },
              },
            }}
          >
            <ClickAwayListener
              onClickAway={() => handleCloseSubMenu(navItem.id)}
            >
              <div
                className={`bg-white border p-s2 ${
                  site === WEBSITE.EDGEPOINT
                    ? 'border-greyLight'
                    : 'border-primary'
                }`}
              >
                <ul>
                  {navItem.menu?.map((subNavItem) => {
                    return (
                      <SubNavigationItem
                        key={`sub-nav-item-${navItem.id}-${subNavItem.id}`}
                        className="mt-s2 first:mt-0"
                      >
                        <Link link={subNavItem}>{subNavItem.text}</Link>
                      </SubNavigationItem>
                    );
                  })}
                </ul>
              </div>
            </ClickAwayListener>
          </Popper>
        </>
      );
    } else {
      // simple navigation item link
      menuItemElement = (
        <Link link={navItem} className="uppercase">
          {navItem.text}
        </Link>
      );
    }

    return (
      <MainNavigationItem
        key={`main-nav-item-${navItem.id}`}
        className={`flex items-center ml-s3 lg:ml-m2 first:ml-0 ${
          // only being on an internal page would require an active classname
          location?.pathname === `/${navItem.page?.slug}` ? 'active' : ''
        }`}
      >
        {menuItemElement}
      </MainNavigationItem>
    );
  };

  // mobile menu state
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileSubNavOpen, setMobileSubNavOpen] = React.useState('');

  function handleMobileMenuToggle() {
    setMobileOpen(!mobileOpen);
    // close all sub navs
    setMobileSubNavOpen('');
  }
  const handleMobileAccordionChange = (id) => {
    setMobileSubNavOpen(id);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header ref={HeaderRef} className="relative z-50">
      <SkipLink href="#main">Skip to main content</SkipLink>
      {/* Desktop navigation */}
      <div
        className={`border-t-2 ${
          site === WEBSITE.EDGEPOINT ? 'border-secondary' : 'border-primary'
        } hidden lg:block`}
      >
        <div
          ref={StickyNavigationRef}
          className={`fixed top-0 left-0 transition-transform ${
            showStickyNav ? '' : '-translate-y-full'
          } bg-white w-full h-[80px] px-l1 flex items-center border-t ${
            site === WEBSITE.EDGEPOINT
              ? 'border-t-secondary'
              : 'border-t-primary'
          } border-b border-b-greyLight`}
        >
          {mobileLogo && (
            <div>
              <LocalizedLink
                language={locale}
                to={logoLinkUrl}
                className="flex items-center w-[100px]"
              >
                <ContentfulImage
                  image={mobileLogo}
                  alt={
                    mobileLogo?.description || site === WEBSITE.EDGEPOINT
                      ? 'Edgepoint'
                      : 'Cymbria'
                  }
                />
              </LocalizedLink>
            </div>
          )}
          <nav className="ml-auto flex items-center">
            {mainNav && (
              <ul className="flex justify-center">
                {mainNav?.map(createStickyNavItem)}
              </ul>
            )}
            <div className="ml-s1">
              <button type="button" onClick={handleClickStickySearchButton}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer color-primary opacity-80"
                >
                  <path
                    d="M15.3125 14.0469L10.9503 9.68398C11.6533 8.71794 12.0315 7.55366 12.0303 6.35891C12.0303 3.23176 9.48605 0.6875 6.35891 0.6875C3.23176 0.6875 0.6875 3.23176 0.6875 6.35891C0.6875 9.48605 3.23176 12.0303 6.35891 12.0303C7.55366 12.0315 8.71794 11.6533 9.68398 10.9503L14.0469 15.3125L15.3125 14.0469ZM6.35891 10.2391C5.59138 10.2392 4.84106 10.0116 4.20286 9.58526C3.56465 9.15889 3.06722 8.55283 2.77346 7.84374C2.47971 7.13465 2.40283 6.35437 2.55255 5.60158C2.70227 4.8488 3.07187 4.15732 3.61459 3.61459C4.15732 3.07187 4.8488 2.70227 5.60158 2.55255C6.35437 2.40283 7.13465 2.47971 7.84374 2.77346C8.55283 3.06722 9.15889 3.56465 9.58526 4.20286C10.0116 4.84106 10.2392 5.59138 10.2391 6.35891C10.2379 7.38763 9.8287 8.37387 9.10128 9.10128C8.37387 9.8287 7.38763 10.2379 6.35891 10.2391Z"
                    fill="#231F20"
                    style={{
                      transform: 'translate(8px, 12px)',
                    }}
                  />
                </svg>
              </button>

              <Popper
                id={`sticky-nav-search`}
                anchorEl={searchPopperAnchorElement}
                open={Boolean(searchPopperAnchorElement)}
                disablePortal
                placement="bottom-end"
                popperOptions={{
                  modifiers: {
                    offset: {
                      offset: `-${themeContext.spacing.s2},0`,
                    },
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClickStickySearchButton}>
                  <div className="flex items-center justify-center min-w-[400px] px-s3 py-s2 bg-white border border-greyLight">
                    <Search
                      id="desktopStickySearchForm"
                      className={`flex-1`}
                      subsite={subsite}
                    />
                    <button
                      type="button"
                      onClick={handleClickStickySearchButton}
                      className="ml-s1"
                    >
                      <Close fontSize="small" className="opacity-80" />
                    </button>
                  </div>
                </ClickAwayListener>
              </Popper>
            </div>
          </nav>
        </div>

        {/* Top navigation bar */}
        <div className="container wide flex">
          {sisterSiteNav && (
            <div className="py-s1 px-s2 rounded-b-md bg-greyLight">
              <Link link={sisterSiteNav} language={locale}>
                <Typography
                  variant="navLink"
                  className="flex items-center uppercase"
                >
                  {sisterSiteNav.text}
                </Typography>
              </Link>
            </div>
          )}

          {/* Instutional link in top nav */}
          {institutionalLinkNav && site === WEBSITE.EDGEPOINT && (
            <ul className="flex">
              <InstitutionalNavigationItem className="py-s1 pl-s2 flex items-center">
                <Typography variant="navLink" className="flex items-center">
                  <Link link={institutionalLinkNav} language={locale}>
                    {institutionalLinkNavTitle}
                  </Link>
                </Typography>
              </InstitutionalNavigationItem>
            </ul>
          )}

          {languageNav && (
            <ul className="flex">
              {config.map((configLocale) => {
                // do not render current locale language option
                if (configLocale?.code === locale) {
                  return;
                }

                // do not allow cn on cymbria
                if (configLocale?.code === 'cn' && site === WEBSITE.CYMBRIA) {
                  return;
                }

                // get the pathname
                const splitPath = location.pathname.split('/');
                // remove any language codified in url
                const normalizedPath = splitPath
                  ?.filter(
                    (value) =>
                      value !== 'en' &&
                      value !== 'fr' &&
                      value !== 'cn' &&
                      value.length > 0,
                  )
                  ?.join('/');
                const path =
                  // always link to home when visiting or leaving CN pages
                  configLocale.code === 'cn' || locale === 'cn'
                    ? ''
                    : normalizedPath;
                let toPath = path.startsWith('/') ? path : `/${path}`;
                toPath = toPath.endsWith('/') ? toPath : `${toPath}/`;

                return (
                  <LanguageNavigationItem
                    key={configLocale.code}
                    className="py-s1 pl-s2 flex items-center"
                  >
                    <Typography variant="navLink" className="flex items-center">
                      <LocalizedLink
                        language={configLocale.code}
                        to={toPath}
                        state={location['state']}
                      >
                        {configLocale.localName}
                      </LocalizedLink>
                    </Typography>
                  </LanguageNavigationItem>
                );
              })}
            </ul>
          )}

          {wealthLinkNav && (
            <div className="ml-auto">
              <div className="flex py-s1 px-s2 rounded-b-md bg-greyLight">
                {wealthLinkNavTitle && (
                  <Typography
                    variant="nav"
                    className="flex items-center uppercase"
                  >
                    {wealthLinkNavTitle}
                  </Typography>
                )}
                <ul className="flex">
                  {wealthLinkNav.map((navItem, index) => {
                    return (
                      <li key={navItem.id} className="pl-s2 flex items-center">
                        <Typography
                          variant="navLink"
                          className="flex items-center"
                        >
                          <Link link={navItem}>{navItem.text}</Link>
                          {index !== wealthLinkNav.length - 1 ? (
                            <span className="ml-s2">|</span>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* Logo and search section */}
        <div className="container wide mt-m4 flex">
          <div className="flex-1 ml-auto invisible"></div>
          <div className="flex-1 flex justify-center">
            {logo && (
              <LocalizedLink language={locale} to={logoLinkUrl}>
                <ContentfulImage
                  image={logo}
                  alt={logo.description || `${site} Logo`}
                />
              </LocalizedLink>
            )}
          </div>
          <div className="flex-1 ml-auto flex justify-end items-end">
            <Search id="desktopSearchForm" subsite={subsite} />
          </div>
        </div>

        {/* Main navigation */}
        {mainNav && (
          <nav className="px-s3 mt-m2 pb-s3">
            <ul className="flex justify-center">
              {mainNav?.map(createMainNavItem)}
            </ul>
          </nav>
        )}
      </div>

      {/* Mobile navigation */}
      <div
        className={`lg:hidden fixed flex flex-col w-full bg-opacity-70 bg-black ${
          site === WEBSITE.EDGEPOINT
            ? 'border-t-2 border-secondary'
            : 'border-t-2 border-b-2 border-primary'
        } ${
          mobileOpen
            ? 'inset-y-0 overflow-y-scroll overflow-x-hidden border-b-0'
            : ''
        }`}
      >
        <div className={`bg-white`}>
          <div className="container h-m4 flex items-center">
            {mobileLogo && (
              <LocalizedLink
                language={locale}
                to={logoLinkUrl}
                className="max-w-[145px] flex items-center"
              >
                <ContentfulImage
                  image={mobileLogo}
                  alt={
                    mobileLogo?.description || site === WEBSITE.EDGEPOINT
                      ? 'Edgepoint'
                      : 'Cymbria'
                  }
                />
              </LocalizedLink>
            )}
            <button
              aria-label="Open drawer"
              onClick={handleMobileMenuToggle}
              className="ml-auto"
            >
              {mobileOpen ? <Close /> : <Menu />}
            </button>
          </div>
        </div>
        <Collapse in={mobileOpen}>
          <nav
            className={`${
              site === WEBSITE.EDGEPOINT ? '' : 'border-b-2 border-primary'
            }`}
          >
            <div className="py-s3 bg-white">
              <div className="container">
                {mainNav && mainNav.length > 0 && (
                  <ul className="">
                    {mainNav?.map((navItem, index) => {
                      let mobileNavItem = <></>;
                      if (navItem.menu && navItem.menu.length > 0) {
                        mobileNavItem = (
                          <StyledAccordion
                            square
                            expanded={mobileSubNavOpen === navItem.id}
                            onChange={() =>
                              handleMobileAccordionChange(navItem.id)
                            }
                          >
                            <AccordionSummary
                              expandIcon={
                                <ArrowDropDown
                                  className={
                                    mobileSubNavOpen === navItem.id
                                      ? 'text-secondary'
                                      : ''
                                  }
                                />
                              }
                              aria-controls={`panel1bh-content-${navItem.id}`}
                              id={`panel1bh-header-${navItem.id}`}
                            >
                              <Typography
                                as="div"
                                variant="navLink"
                                className="uppercase"
                              >
                                {navItem.text}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul>
                                {navItem.menu.map((subNavItem, index) => (
                                  <li
                                    key={`${navItem.id}-${subNavItem.id}-${index}`}
                                    className="mt-s3"
                                  >
                                    <Link link={subNavItem}>
                                      <Typography variant="navLink">
                                        {subNavItem.text}
                                      </Typography>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </AccordionDetails>
                          </StyledAccordion>
                        );
                      } else {
                        mobileNavItem = (
                          <Link link={navItem}>
                            <Typography variant="navLink" className="uppercase">
                              {navItem.text}
                            </Typography>
                          </Link>
                        );
                      }

                      return (
                        <li
                          key={`${navItem.id}-${index}`}
                          className="first:mt-0 mt-s3"
                        >
                          {mobileNavItem}
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="mt-s4">
                  <Search id="mobileSearchForm" subsite={subsite} />
                </div>
                <div className="mt-s4">
                  <ul className="flex">
                    {sisterSiteNav && (
                      <MobileSisterLanguageNavItem>
                        <Link link={sisterSiteNav} language={locale}>
                          <Typography
                            variant="navLink"
                            className="flex items-center uppercase text-xs"
                          >
                            {sisterSiteNav.text}
                          </Typography>
                        </Link>
                      </MobileSisterLanguageNavItem>
                    )}
                    {config &&
                      config.map((configLocale) => {
                        // do not render current locale language option
                        if (configLocale?.code === locale) {
                          return;
                        }

                        // do not allow cn on cymbria
                        if (
                          configLocale?.code === 'cn' &&
                          site === WEBSITE.CYMBRIA
                        ) {
                          return;
                        }

                        // get the pathname
                        const splitPath = location.pathname.split('/');
                        // remove any language codified in url
                        const normalizedPath = splitPath
                          ?.filter(
                            (value) =>
                              value !== 'en' &&
                              value !== 'fr' &&
                              value !== 'cn' &&
                              value.length > 0,
                          )
                          ?.join('/');
                        const path =
                          // always link to home when visiting or leaving CN pages
                          configLocale.code === 'cn' || locale === 'cn'
                            ? ''
                            : normalizedPath;
                        let toPath = path.startsWith('/') ? path : `/${path}`;
                        toPath = toPath.endsWith('/') ? toPath : `${toPath}/`;

                        return (
                          <MobileSisterLanguageNavItem key={configLocale.code}>
                            <LocalizedLink
                              language={configLocale.code}
                              to={toPath}
                              state={location['state']}
                            >
                              <Typography
                                variant="navLink"
                                className="flex items-center text-xs"
                              >
                                {configLocale.localName}
                              </Typography>
                            </LocalizedLink>
                          </MobileSisterLanguageNavItem>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            {/* MOBILE Institutional link */}
            {institutionalLinkNav && site === WEBSITE.EDGEPOINT && (
              <div className="py-s2 bg-greyLighter">
                <div className="container">
                  <ul className="flex">
                    <li className="flex-0">
                      <Typography
                        variant="navLink"
                        className="flex items-center uppercase text-[10px]"
                      >
                        {institutionalLinkNav?.text}
                      </Typography>
                    </li>
                    <li className="ml-s3 flex items-center">
                      <Link link={institutionalLinkNav} language={locale}>
                        <Typography
                          variant="navLink"
                          className="flex items-center text-xs font-medium"
                        >
                          {institutionalLinkNavTitle}
                        </Typography>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {/* MOBILE Instutional Link */}

            {wealthLinkNav && (
              <div className="py-s2 bg-greyLight">
                <div className="container">
                  <ul className="flex">
                    {wealthLinkNavTitle && (
                      <li className="flex-0">
                        <Typography
                          variant="navLink"
                          className="flex items-center uppercase text-[10px]"
                        >
                          {wealthLinkNavTitle}:
                        </Typography>
                      </li>
                    )}
                    {wealthLinkNav.map((navItem) => {
                      return (
                        <li
                          key={navItem.id}
                          className="ml-s3 flex items-center"
                        >
                          <Link link={navItem} className="hover:underline">
                            <Typography
                              variant="navLink"
                              className="flex items-center text-xs font-medium"
                            >
                              {navItem.text}
                            </Typography>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </nav>
        </Collapse>
      </div>
    </header>
  );
};

export default Header;
