// ArticleSet allows for displaying previews with links to articles in a group
// They can be grouped in different ways

import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocalization } from 'gatsby-theme-i18n';
import { Tabs, Tab, Select, MenuItem } from '@material-ui/core';
import {
  ContentfulComponentCtaButton,
  ContentfulComposePage,
  ContentfulTopicArticle,
  ContentfulTopicCategory,
} from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import Button from './Button';
import Typography from './Typography';
import Card from './Card';
import Link from './Link';
import { useGlobalState } from '../hooks/useGlobalState';
import ContentfulImage from './ContentfulImage';
import { getTranslation } from '../services/translation.service';
import { formatLocalizedDate } from '../services/localization.service';
import { getDateWithLocaleOffset } from '../services/date.service';

export const CONTENTFUL_FITLER_SORT_OPTIONS = {
  ALPHABETICAL_DESCENDING: 'Alphabetical Descending',
  NUMBERS_DESCENDING: 'Numbers Descending',
  NUMBERS_FIRST: 'Numbers First',
};

const DEFAULT_LIMIT = 6;

interface CategoryFilter {
  id: string;
  title: string;
}

const FeaturedArticleList = styled.ul`
  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    margin: 0 calc(-${(props) => props.theme.spacing.s3} / 2);
    overflow: hidden;
  }
`;
const FeaturedArticleListItem = styled.li`
  @media (min-width: ${(props) => props.theme.breakpoint.lg}) {
    padding: 0 calc(${(props) => props.theme.spacing.s3} / 2);
  }
`;
const StyledSelect = styled(Select)``;
const StyledTabs = styled(Tabs)`
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.color.greyLight};
  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.color.secondary};
  }

  .MuiTabs-scrollButtons {
    width: initial;
    &:first-child {
      height: 100%;
      position: absolute;
      left: 0;
      opacity: 1;
      z-index: 1;
    }

    &.MuiTabs-scrollButtonsDesktop {
      display: flex;

      &.Mui-disabled:first-child {
        visibility: hidden;
      }
    }
  }
`;
const StyledTab = styled(Tab)`
  &.MuiTab-root {
    ${(props) => props.theme.typography.body}
    text-transform: none;
    min-width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  &.Mui-selected {
    color: ${(props) => props.theme.color.secondary};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }

  &:not(:first-of-type) {
    margin-left: ${(props) => props.theme.spacing.m1};
  }
`;

interface ArticleSetProps {
  button?: ContentfulComponentCtaButton;
  categories?: ContentfulTopicCategory[];
  className?: string;
  featuredArticles?: ContentfulComposePage[];
  curatedArticles?: ContentfulComposePage[];
  headlineAlignment?: string;
  limit?: number;
  subheading?: string;
  title?: string;
  variant?: string;
  showFilters?: boolean;
  filterSortOptions?: string[];
  groupByCategory?: boolean;
  allCategoriesLabel?: string;
  isButtonForLoadMore?: boolean;
}
const ArticleSet: React.FC<ArticleSetProps> = (props) => {
  const {
    button,
    categories,
    className,
    featuredArticles,
    curatedArticles,
    headlineAlignment,
    limit,
    subheading,
    title,
    variant,
    showFilters,
    filterSortOptions,
    groupByCategory,
    allCategoriesLabel,
    isButtonForLoadMore,
  } = props;
  const { locale } = useLocalization();
  const { pageContext, site } = useGlobalState();
  const theme = React.useContext(ThemeContext);
  const [availableArticles, setAvailableArticles] = React.useState<
    ContentfulTopicArticle[]
  >([]);
  const [displayedArticles, setDisplayedArticles] = React.useState<
    ContentfulTopicArticle[]
  >([]);

  // state for groupByCategory
  const [allLabel] = React.useState(
    allCategoriesLabel ?? getTranslation('All', locale),
  );
  const [categoryGroupFilters, setCategoryGroupFilters] = React.useState<
    CategoryFilter[]
  >([]);
  const [selectedCategoryGroup, setSelectedCategoryGroup] =
    React.useState<CategoryFilter>();
  const [selectedCategoryTab, setSelectedCategoryTab] = React.useState(0);
  const handleGroupByCategoryTabChange = (event, newValue) => {
    const newSelectedCategoryGroup = categoryGroupFilters[newValue];

    // change tab
    setSelectedCategoryTab(newValue);

    // update selected category state
    setSelectedCategoryGroup(newSelectedCategoryGroup);

    // reset this so we don't show all articles again when changing categories
    setCurrentSkip(0);

    // React.useEffect will update rendering filtered articles
  };

  // state for showFilters
  const [categoryTagFilters, setCategoryTagFilters] = React.useState<
    CategoryFilter[]
  >([]);
  const [selectedCategoryTagFilter, setSelectedCategoryTagFilter] =
    React.useState('');
  const handleShowFiltersCategoryChange = (event) => {
    setSelectedCategoryTagFilter(event.target.value);

    // reset this so we don't show all articles again when changing categories
    setCurrentSkip(0);
  };

  // state for displaying more articles
  const [displayLimit] = React.useState(limit || DEFAULT_LIMIT);
  const [currentSkip, setCurrentSkip] = React.useState(0);
  const [totalArticles, setTotalArticles] = React.useState(0);
  /** Increases the amount of displayed articles by articleDisplayLimit */
  const onClickLoadMoreButton = () => {
    setCurrentSkip(currentSkip + displayLimit);
  };

  React.useEffect(() => {
    const categoryArticles: ContentfulTopicArticle[] =
      categories
        ?.map((category) => {
          // collect all articles from categories
          return category.topic__article;
        })
        // remove empty arrays
        ?.filter((articleList) => articleList)
        // flatten
        ?.flat()
        // remove articles appearing in multiple teams
        ?.filter(
          (article, index, array) =>
            array.findIndex((t) => t.id === article.id) === index,
        )
        // remove articles not in current global website or node_locale
        ?.filter(
          (article) =>
            article.compose__page?.some(
              (page) =>
                page.website?.contentful_id ===
                pageContext?.globalWebsiteContentfulId,
            ) && article.node_locale === pageContext?.node_locale,
        )
        ?.sort(
          (a, b) =>
            new Date(b.publicationDate).getTime() -
            new Date(a.publicationDate).getTime(),
        ) ?? [];
    setAvailableArticles(categoryArticles);
  }, [categories, pageContext]);

  React.useEffect(() => {
    if (showFilters && availableArticles && availableArticles.length > 0) {
      // setup category filters for use of showFilters tag dropdown
      let newCategoryTagFilters: CategoryFilter[] = [];

      // collect all category filters
      availableArticles?.map((article) => {
        article.tags?.forEach((tag) => {
          if (!newCategoryTagFilters.some((newTag) => newTag.id === tag.id)) {
            newCategoryTagFilters.push({
              id: tag.id,
              title: tag.title,
            });
          }
        });
      });

      // separate into numbers/alpha titles and sort
      const numberFilters =
        newCategoryTagFilters
          .filter((item) => !isNaN(parseFloat(item?.title)))
          .sort((a, b) => {
            const lowerCaseA = a?.title?.toLocaleLowerCase();
            const lowerCaseB = b?.title?.toLocaleLowerCase();
            const comparison = filterSortOptions?.includes(
              CONTENTFUL_FITLER_SORT_OPTIONS.NUMBERS_DESCENDING,
            )
              ? lowerCaseB?.localeCompare(lowerCaseA)
              : lowerCaseA?.localeCompare(lowerCaseB);
            return comparison;
          }) || [];
      const alphaFilters =
        newCategoryTagFilters
          .filter((item) => isNaN(parseFloat(item?.title)))
          .sort((a, b) => {
            const lowerCaseA = a?.title?.toLocaleLowerCase();
            const lowerCaseB = b?.title?.toLocaleLowerCase();
            const comparison = filterSortOptions?.includes(
              CONTENTFUL_FITLER_SORT_OPTIONS.ALPHABETICAL_DESCENDING,
            )
              ? lowerCaseB?.localeCompare(lowerCaseA)
              : lowerCaseA?.localeCompare(lowerCaseB);
            return comparison;
          }) || [];

      // place numbers first or last depending on setting
      // default to first
      if (
        filterSortOptions?.includes(
          CONTENTFUL_FITLER_SORT_OPTIONS.NUMBERS_FIRST,
        )
      ) {
        newCategoryTagFilters = [...numberFilters, ...alphaFilters];
      } else {
        newCategoryTagFilters = [...alphaFilters, ...numberFilters];
      }

      // add All tag so it displays first
      const allTopicsCategoryTagFilter = {
        id: allLabel,
        title: allLabel,
      };
      newCategoryTagFilters.unshift(allTopicsCategoryTagFilter);

      setCategoryTagFilters(newCategoryTagFilters);
      setSelectedCategoryTagFilter(allTopicsCategoryTagFilter.id);
    }
  }, [showFilters, availableArticles]);

  React.useEffect(() => {
    // setup categories for use of groupByCategory
    const newCategoryGroupFilters: CategoryFilter[] = [];

    // collect all categories
    categories?.forEach((category) => {
      newCategoryGroupFilters.push({
        id: category.id,
        title: category.title,
      });
    });

    // mimick shape of category object and add to array in order to display
    // All tab/tab panel
    const allInsightsCategoryGroupFilter = {
      id: allLabel,
      title: allLabel,
    };
    newCategoryGroupFilters.unshift(allInsightsCategoryGroupFilter);

    // set all options for categories
    setCategoryGroupFilters(newCategoryGroupFilters);

    // set default selected category to "all"
    setSelectedCategoryGroup(allInsightsCategoryGroupFilter);
  }, [groupByCategory, availableArticles]);

  // update articles whenever any display state is changed
  React.useEffect(() => {
    updateDisplayedArticles();
  }, [
    currentSkip,
    displayLimit,
    selectedCategoryGroup,
    selectedCategoryTagFilter,
    availableArticles,
  ]);

  /** Updates displayed articles based on current categories, filters, and paging */
  const updateDisplayedArticles = () => {
    let filteredArticles = [];
    if (groupByCategory) {
      filteredArticles = availableArticles.filter((article) => {
        return (
          selectedCategoryGroup.title === allLabel || // return all articles when category is All Insights
          article?.category?.id === selectedCategoryGroup.id
        );
      });
    } else if (showFilters) {
      filteredArticles = availableArticles.filter(
        (article) =>
          selectedCategoryTagFilter === allLabel || // show all articles when tag is All Topics
          article?.tags?.some((tag) => tag.id === selectedCategoryTagFilter),
      );
    } else {
      // no filtering
    }

    // show limit
    let slicedFilteredArticles = filteredArticles.slice(
      0,
      currentSkip + displayLimit,
    );

    if (slicedFilteredArticles.length === 0) {
      // at least put in some available articles
      slicedFilteredArticles = availableArticles.slice(
        0,
        currentSkip + displayLimit,
      );
    }

    // finally, add curated articles to top and hide display
    let curatedArticlesLength = 0;
    if (curatedArticles && curatedArticles.length) {
      const curatedArticlesContent = curatedArticles.map(
        (page) => page.content as ContentfulTopicArticle,
      );
      curatedArticlesLength = curatedArticlesContent?.length || 0;
      slicedFilteredArticles = [
        ...curatedArticlesContent,
        ...slicedFilteredArticles,
      ].slice(0, currentSkip + displayLimit);
    }

    setTotalArticles(filteredArticles.length + curatedArticlesLength);
    setDisplayedArticles(slicedFilteredArticles);
  };

  const renderButton =
    button &&
    (isButtonForLoadMore ? (
      <Button variant={button.variant} onClick={onClickLoadMoreButton}>
        {button.text}
      </Button>
    ) : (
      <Button
        variant={button.variant}
        link={button.page?.slug || button.externalUrl}
        openInNewWindow={button.openInNewWindow}
      >
        {button.text}
      </Button>
    ));

  const renderCategoryTabs = (
    <div className="mt-m2 pt-1">
      <StyledTabs
        value={selectedCategoryTab}
        onChange={handleGroupByCategoryTabChange}
        aria-label="Category Tabs"
        scrollButtons="auto"
        variant="scrollable"
      >
        {categoryGroupFilters?.map((category, index) => {
          return (
            <StyledTab
              key={`category-tab-${category.id}`}
              label={category.title}
              id={`category-tab-${index}`}
              disableRipple
            />
          );
        })}
      </StyledTabs>
    </div>
  );

  const renderFilterSelect = (
    <StyledSelect
      labelId="show-filters-select-label"
      id="show-filters-select"
      value={selectedCategoryTagFilter}
      onChange={handleShowFiltersCategoryChange}
      fullWidth
    >
      {categoryTagFilters?.map((tag) => {
        return (
          <MenuItem key={`filter-menu-item-${tag.id}`} value={tag.id}>
            {tag.title}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );

  const articleSetHeaderElement = (
    <div className="md:flex items-center">
      <div className="md:w-2/3">
        {title && (
          <Typography
            as="h2"
            variant={site === WEBSITE.EDGEPOINT ? 'h1' : 'h2'}
            className={`${site === WEBSITE.EDGEPOINT ? 'uppercase' : ''} ${
              headlineAlignment === 'center' ? 'text-center' : ''
            }`}
          >
            {title}
          </Typography>
        )}
        {subheading && (
          <Typography
            as="p"
            variant="body"
            className={`mt-s2 ${
              headlineAlignment === 'center' ? 'text-center' : ''
            }`}
          >
            {subheading}
          </Typography>
        )}
      </div>

      {!groupByCategory &&
        !showFilters &&
        button &&
        site === WEBSITE.CYMBRIA && (
          <div className="mt-s3 md:mt-0 md:ml-auto">{renderButton}</div>
        )}

      {showFilters && (
        <div className="mt-m2 min-w-[270px] md:mt-0 md:ml-auto">
          {renderFilterSelect}
        </div>
      )}
    </div>
  );

  const renderFeaturedArticles = (
    <FeaturedArticleList className="flex flex-col lg:flex-row">
      {featuredArticles?.map((composePage) => {
        const article = composePage?.content as ContentfulTopicArticle;
        return (
          <FeaturedArticleListItem
            key={`featured-article-${article.id}`}
            className={`flex-1 mt-m2 md:mt-s3 lg:w-1/${featuredArticles.length}`}
          >
            <Card
              variant="vertical"
              body={article.subheading?.subheading}
              caption={article.category?.title}
              title={article.title}
              image={article.featureImage}
              date={article.publicationDate}
              page={article.compose__page?.[0]}
            />
          </FeaturedArticleListItem>
        );
      })}
    </FeaturedArticleList>
  );

  // choose variant html
  // default variant: 'cards'
  let articleSetVariant = <></>;

  switch (variant) {
    case 'compact': {
      articleSetVariant = (
        <div className={className}>
          {title && (
            <Typography
              as="h2"
              variant="h2"
              className={`leading-none ${
                site === WEBSITE.EDGEPOINT ? 'uppercase' : ''
              }`}
            >
              {title}
            </Typography>
          )}
          {displayedArticles && displayedArticles.length > 0 && (
            <ul>
              {displayedArticles?.map((article) => {
                return (
                  <li
                    key={`article-${article.id}`}
                    className="mt-s2 pb-s2 md:mt-s3 first:mt-0 border-b border-secondary last:border-b-0"
                  >
                    <div className="mt-s1 flex flex-col md:flex-row">
                      <div className="flex-none md:w-1/3">
                        {article.publicationDate && (
                          <Typography
                            as="time"
                            variant="date"
                            className={`block text-greyDark leading-loose whitespace-nowrap`}
                          >
                            {formatLocalizedDate(
                              getDateWithLocaleOffset(article.publicationDate),
                              locale,
                              {
                                month: 'long',
                              },
                            )}
                          </Typography>
                        )}
                      </div>
                      <div className="mt-s1 flex-initial md:mt-0 md:ml-s4 md:w-3/4">
                        {article.title && (
                          <Link
                            language={locale}
                            link={{ page: article.compose__page?.[0] }}
                          >
                            <Typography
                              as="p"
                              variant="body"
                              className={`font-medium leading-loose`}
                            >
                              {article.title}
                            </Typography>
                          </Link>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
      break;
    }
    case 'preview': {
      articleSetVariant = (
        <div className={`container ${className}`}>
          <div className="flex">
            {title && (
              <Typography
                as="h2"
                variant="h2"
                className={site === WEBSITE.EDGEPOINT ? 'uppercase' : ''}
              >
                {title}
              </Typography>
            )}
            {button && (
              <div className="ml-auto">
                <Button link={button.externalUrl || button.page?.slug}>
                  {button.text}
                </Button>
              </div>
            )}
          </div>
          {displayedArticles && displayedArticles.length > 0 ? (
            <ul className="sm:grid sm:grid-cols-2 sm:gap-s3 md:grid-cols-3">
              {displayedArticles.map((article) => {
                // normalize slug with preceding slash
                const articleSlug = article.compose__page?.[0]?.slug.startsWith(
                  '/',
                )
                  ? article.compose__page?.[0]?.slug
                  : `/${article.compose__page?.[0]?.slug}`;

                return (
                  <li
                    key={article.id}
                    className={`mt-s3 first:mt-s4 sm:first:mt-s3`}
                  >
                    <Link
                      language={locale}
                      link={{ page: article.compose__page?.[0] }}
                      className="block"
                    >
                      <div className="relative">
                        {article.featureImage ? (
                          <ContentfulImage
                            image={article.featureImage}
                            alt={article.featureImage?.description || ''}
                            className="filter grayscale w-full sm:h-[170px]"
                          />
                        ) : (
                          <StaticImage
                            src="../images/placeholder.png"
                            alt="Placeholder"
                            className="filter grayscale w-full sm:h-[170px]"
                          />
                        )}
                        <svg
                          width="65"
                          height="65"
                          viewBox="0 0 65 65"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute transform top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4"
                        >
                          <path
                            d="M32.4636 0.396484C14.7836 0.396484 0.399414 14.776 0.399414 32.4502C0.399414 50.1245 14.7836 64.504 32.4636 64.504C50.1435 64.504 64.5277 50.1245 64.5277 32.4502C64.5277 14.776 50.1435 0.396484 32.4636 0.396484ZM23.8309 46.0114V18.889L46.0292 32.4502L23.8309 46.0114Z"
                            fill={theme.color.white}
                            stroke={theme.color.black}
                          />
                        </svg>
                      </div>
                      {article.title && (
                        <Typography as="div" variant="body" className="mt-s2">
                          {article.title}
                        </Typography>
                      )}
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Typography as="div" variant="body" className="mt-s3">
              No articles to display
            </Typography>
          )}
        </div>
      );
      break;
    }
    case 'cards': {
      // fall through to default
      articleSetVariant = (
        <div className={className}>
          <div className="container">
            {articleSetHeaderElement}

            {renderFeaturedArticles}

            {groupByCategory && renderCategoryTabs}

            {displayedArticles.length > 0 ? (
              <ul>
                {displayedArticles?.map((article) => {
                  return (
                    <li key={`article-${article.id}`} className="mt-m2">
                      <Card
                        body={article.subheading?.subheading}
                        caption={article.category?.title}
                        title={article.title}
                        image={article.featureImage}
                        date={article.publicationDate}
                        page={article.compose__page?.[0]}
                        variant="horizontal"
                      />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <Typography variant="body" className="font-medium">
                No articles found.
              </Typography>
            )}

            {button && (
              <div
                className={`mt-m2 flex  ${
                  site === WEBSITE.EDGEPOINT
                    ? 'justify-center'
                    : 'md:justify-center'
                }`}
              >
                {/* Load More button for both Edgepoint/Cymbria */}
                {(groupByCategory || showFilters) &&
                  currentSkip + displayLimit < totalArticles &&
                  renderButton}

                {/* View all button placement for Edgepoint */}
                {!groupByCategory &&
                  !showFilters &&
                  site === WEBSITE.EDGEPOINT &&
                  renderButton}
              </div>
            )}
          </div>
        </div>
      );
      break;
    }
    case 'summary': {
      articleSetVariant = (
        <div className={className}>
          <div className="container">
            {articleSetHeaderElement}

            {renderFeaturedArticles}

            {groupByCategory && renderCategoryTabs}

            {displayedArticles.length > 0 ? (
              <ul className="md:grid md:grid-cols-2 md:auto-cols-auto md:gap-x-s3 md:gap-y-m2">
                {displayedArticles?.map((article) => {
                  return (
                    <li key={`article-${article.id}`} className="mt-m2">
                      <Link
                        language={locale}
                        link={{ page: article.compose__page?.[0] }}
                        className="block no-underline"
                      >
                        {article.category?.title && (
                          <Typography
                            as="div"
                            variant="tag"
                            className="text-secondary uppercase"
                          >
                            {article.category?.title}
                          </Typography>
                        )}
                        {article.title && (
                          <Typography as="div" variant="h3" className="mt-s1">
                            {article.title}
                          </Typography>
                        )}
                        {article.subheading?.subheading && (
                          <Typography as="div" variant="body" className="mt-s1">
                            {article.subheading?.subheading}
                          </Typography>
                        )}
                        {article.publicationDate && (
                          <Typography as="div" variant="date" className="mt-s1">
                            {formatLocalizedDate(
                              getDateWithLocaleOffset(article.publicationDate),
                              locale,
                              {
                                month: 'long',
                              },
                            )}
                          </Typography>
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <Typography variant="body" className="font-medium">
                No articles found.
              </Typography>
            )}

            {button && (
              <div
                className={`mt-m2 flex  ${
                  site === WEBSITE.EDGEPOINT
                    ? 'justify-center'
                    : 'md:justify-center'
                }`}
              >
                {/* Load More button for both Edgepoint/Cymbria */}
                {(groupByCategory || showFilters) &&
                  currentSkip + displayLimit < totalArticles &&
                  renderButton}

                {/* View all button placement for Edgepoint */}
                {!groupByCategory &&
                  !showFilters &&
                  site === WEBSITE.EDGEPOINT &&
                  renderButton}
              </div>
            )}
          </div>
        </div>
      );
      break;
    }
  }

  return articleSetVariant;
};

export default ArticleSet;
