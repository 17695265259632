import Highcharts from 'highcharts';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { useLocalization } from 'gatsby-theme-i18n';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {
  ContentfulComponentDataVisualizationFootnote,
  ContentfulComponentDataVisualizationSubheadingTextNode,
  ContentfulTopicTabularDataSet,
} from '../../graphql-types';
import { HEADLINE_ORIENTATION } from '../types/headlineOrientation.enum';
import { getTranslation } from '../services/translation.service';
import Typography from './Typography';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import {
  getHighchartsOptions,
  getPieChartOptions,
  getSeriesData,
  getSeriesFromDataFile,
  HighchartsPieChartSeries,
  HIGHCHARTS_EN_OPTIONS,
  HIGHCHARTS_FR_OPTIONS,
} from '../services/highcharts.service';
import useWindowWidth from '../hooks/useWindowWidth';
import { useGlobalState } from '../hooks/useGlobalState';

// register plugins only if client-side
// cf. https://github.com/highcharts/highcharts-react/issues/76
if (typeof Highcharts === 'object') {
  highchartsExporting(Highcharts);
  highchartsExportData(Highcharts);
}

const footnoteRichTextOptions: Options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        as="small"
        variant="body"
        className="block mt-s2 text-xs leading-relaxed"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography
        as="span"
        variant="body"
        className="block mt-s2 leading-relaxed"
      >
        {children}
      </Typography>
    ),
  },
};

interface DataVisualizationHighchartsChartProps {
  dataSets?: ContentfulTopicTabularDataSet[];
  showDateSelector?: boolean;
  showAsAtDate?: boolean;
  dateSelectorLabel?: string;
  footnote?: ContentfulComponentDataVisualizationFootnote;
  headlineOrientation?: string;
  subheading?: ContentfulComponentDataVisualizationSubheadingTextNode;
  title?: string;
  type: 'line-chart' | 'bar-chart' | 'column-chart';
}

const DataVisualizationHighchartsChart: React.FC<
  DataVisualizationHighchartsChartProps
> = (props) => {
  const {
    dataSets,
    footnote,
    headlineOrientation,
    subheading,
    showAsAtDate,
    title,
    type,
  } = props;
  const { locale } = useLocalization();
  const windowWidth = useWindowWidth();

  // "As at" formatting
  const asAtText = getTranslation('AsAt', locale);
  const { asAtDate } = useGlobalState();
  const formattedAsAtDate = new Date(asAtDate).toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });

  // highchart state
  const [highchartOptions, setHighchartOptions] =
    React.useState<Highcharts.Options>(null);
  const highchartsRef = React.useRef<HighchartsReact.RefObject>(null);

  const setupHighchartOptions = async (
    dataSets: ContentfulTopicTabularDataSet[],
    type: string,
  ) => {
    const dataSetType =
      dataSets?.[0]?.json !== null
        ? 'JSON'
        : dataSets?.[0]?.dataFile !== null
        ? 'DATAFILE'
        : dataSets?.[0]?.rows !== null
        ? 'ROWS'
        : null;

    let options: Highcharts.Options;

    switch (dataSetType) {
      case 'JSON': {
        let json;
        try {
          json = JSON.parse(dataSets?.[0]?.json?.internal?.content);
        } catch (err) {
          // noop
        }

        // delete width and height properties from json if they exist
        if (json.chart.width) {
          delete json.chart.width;
        }

        if (json.chart.height) {
          delete json.chart.height;
        }

        options = json;
        break;
      }
      case 'DATAFILE': {
        const seriesName = dataSets?.[0]?.title;
        let seriesData: HighchartsPieChartSeries[] = [];
        // data is from an excel file in the dataFile field
        seriesData = await getSeriesFromDataFile(dataSets?.[0]?.dataFile);

        // sort highest y to lowest
        seriesData.sort((a, b) => b.y - a.y);

        options = getPieChartOptions(type, locale, seriesName, seriesData);
        break;
      }
      case 'ROWS': {
        const series = await Promise.all(getSeriesData(dataSets, type));
        options = getHighchartsOptions(
          series,
          dataSets?.[0]?.columns?.[0]?.title,
          dataSets?.[0]?.columns?.[1]?.title,
          dataSets?.[0]?.columns?.[1]?.type,
          locale,
        );
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.warn('No dataset found.');
      }
    }

    // must set language options globally before chart is initialized
    // cf. https://api.highcharts.com/highcharts/lang
    const highchartLocaleOptions =
      locale === 'fr' ? HIGHCHARTS_FR_OPTIONS : HIGHCHARTS_EN_OPTIONS;
    Highcharts.setOptions(highchartLocaleOptions);
    setHighchartOptions(options);
  };

  React.useEffect(() => {
    setupHighchartOptions(dataSets, type);

    setTimeout(function () {
      const chart = highchartsRef.current?.chart;
      chart?.reflow();
    }, 500);
  }, []);

  // reflow chart on window resize
  React.useEffect(() => {
    const chart = highchartsRef.current?.chart;
    if (chart) {
      chart.reflow();
    }
  }, [windowWidth]);

  return (
    <div className={`container`}>
      {/* split variant */}
      {headlineOrientation === HEADLINE_ORIENTATION.SPLIT ? (
        <div className="lg:grid lg:grid-cols-10 lg:gap-x-s3">
          <div className="lg:col-span-4">
            {title && (
              <Typography as="h2" variant="h2" className="uppercase">
                {title}
              </Typography>
            )}
            {subheading?.subheading && (
              <Typography as="div" variant="date" className="mt-s1">
                {subheading.subheading}
              </Typography>
            )}
            {showAsAtDate && (
              <Typography as="div" variant="date" className="mt-s1 mb-s2">
                {`${asAtText} ${formattedAsAtDate}`}
              </Typography>
            )}
          </div>
          {highchartOptions && (
            <div className="lg:col-span-6">
              <HighchartsReact
                highcharts={Highcharts}
                options={highchartOptions}
                ref={highchartsRef}
              />
              {footnote && (
                <Typography
                  as="small"
                  variant="body"
                  className="block mt-s2 text-xs leading-relaxed mb-s2"
                >
                  {renderContentfulRichText(
                    footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    footnoteRichTextOptions,
                  )}
                </Typography>
              )}
            </div>
          )}
        </div>
      ) : (
        // centered variant
        <>
          {title && (
            <Typography as="h2" variant="h2" className="uppercase mb-s1">
              {title}
            </Typography>
          )}
          {subheading?.subheading && (
            <Typography as="div" variant="date" className="mb-s1">
              {subheading.subheading}
            </Typography>
          )}
          {showAsAtDate && (
            <Typography as="div" variant="date" className="mt-s1 mb-s2">
              {`${asAtText} ${formattedAsAtDate}`}
            </Typography>
          )}
          {highchartOptions && (
            <HighchartsReact
              highcharts={Highcharts}
              options={highchartOptions}
              ref={highchartsRef}
            />
          )}
          {footnote && (
            <Typography
              as="small"
              variant="body"
              className="block mt-s2 text-xs leading-relaxed mb-s2"
            >
              {renderContentfulRichText(
                footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                footnoteRichTextOptions,
              )}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default DataVisualizationHighchartsChart;
