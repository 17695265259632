// A template to create an article
// Articles are created by client and live in CMS

/* eslint-disable react/display-name */
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { useLocalization } from 'gatsby-theme-i18n';
import { ChevronLeft } from '@material-ui/icons';
import {
  ArticleTemplateQuery,
  ContentfulTopicArticle,
  ContentfulAsset,
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
} from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import edgepointTheme from '../themes/edgepoint';
import cymbriaTheme from '../themes/cymbria';
import Layout from '../components/Layout';
import { PageContext } from '../types/pageContext';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/SEO';
import { useGlobalState } from '../hooks/useGlobalState';
import Typography from '../components/Typography';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import ComponentSelector from '../components/ComponentSelector';
import ContentfulImage from '../components/ContentfulImage';
import Divider from '../components/Divider';
import { getTranslation } from '../services/translation.service';
import { formatLocalizedDate } from '../services/localization.service';
import { getDateWithLocaleOffset } from '../services/date.service';
import Link from '../components/Link';
import CookiePreferences from '../components/CookiePreferences';
import styled from 'styled-components';

// nested <ul> styles
const StyledUl = styled.ul`
  padding-left: 2rem !important;
  list-style-type: disc !important;
  & ul {
    margin-top: 12px !important;
    list-style-type: '-  ' !important;
    & ul {
      list-style-type: disc !important;
      & ul {
        list-style-type: circle !important;
      }
    }
  }
`;
// nested <ol> styles
const StyledOl = styled.ol`
  padding-left: 2rem !important;
  list-style-type: decimal !important;
  & ol {
    margin-top: 12px !important;
    list-style-type: lower-latin !important;
    & ol {
      list-style-type: lower-roman !important;
    }
  }
`;

const articleContentfulRichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography as="p" variant="body" className="mt-s4 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <StyledUl className="list-disc pl-8 mt-s3">{children}</StyledUl>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <StyledOl className="list-decimal pl-8 mt-s3">{children}</StyledOl>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography as="h1" variant="h1" className="mt-m3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography as="h2" variant="h2" className="mt-m3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography as="h3" variant="h3" className="mt-m3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography as="h4" variant="h4" className="mt-m3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography as="h5" variant="h4" className="mt-m3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography as="h6" variant="h4" className="mt-m3">
        {children}
      </Typography>
    ),
    [BLOCKS.HR]: () => <Divider fullWidth className="mt-s3" />,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <ComponentSelector
            contentfulComponent={node.data.target}
            className={node.data.target?.darkerBackground ? '' : '-mx-s3'} // all components are already in a container (except for darker background)
            isChildComponent
          />
        )
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      switch (node.data?.target.__typename) {
        case 'ContentfulAsset': {
          const contentfulAsset = node.data?.target as ContentfulAsset;
          return (
            <ContentfulImage
              image={contentfulAsset}
              alt={contentfulAsset?.gatsbyImageData?.description || ''}
            />
          );
        }
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <ComponentSelector
            contentfulComponent={node.data.target}
            className="-mx-s3" // all components are already in a container
          />
        )
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      // LocalizedLink's language prop is actually optional, but their Typscript type
      // doesn't reflect this:
      // https://github.com/gatsbyjs/themes/issues/123
      if (
        node.data?.target?.website.contentful_id !==
        process.env.GATSBY_GLOBAL_WEBSITE_CONTENTFUL_ID
      ) {
        return (
          <a
            href={`${process.env.GATSBY_OTHER_SITE_URL}/${node.data?.target?.slug}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {children}
          </a>
        );
      } else {
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Link link={{ page: node.data?.target }}>{children}</Link>
        );
      }
    },
  },
};

const articleFootnoteContentfulRichTextOptions = {
  ...articleContentfulRichTextOptions,
  renderNode: {
    ...articleContentfulRichTextOptions.renderNode,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        as="small"
        variant="footerBody3"
        className="block mt-s2 leading-relaxed"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography as="p" variant="body" className="block mt-s2 leading-relaxed">
        {children}
      </Typography>
    ),
  },
};

export const query = graphql`
  query ArticleTemplate($id: String!, $node_locale: String!) {
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      __typename
      slug
      seo {
        ...ContentfulComposeSeoFragment
      }
      content {
        ... on ContentfulTopicArticle {
          title
          publicationDate
          soundCloudUrl
          category {
            title
            relatedPage {
              id
              slug
              title
            }
          }
          author {
            firstName
            lastName
            compose__page {
              slug
            }
          }
          byLine
          body {
            raw
            references {
              ... on ContentfulAsset {
                ...ContentfulAssetFragment
              }
              ... on ContentfulComposePage {
                id
                contentful_id
                __typename
                node_locale
                slug
                website {
                  contentful_id
                }
              }
              ... on ContentfulComponentCalloutCtaSet {
                ...ContentfulComponentCalloutCtaSetFragment
              }
              ... on ContentfulComponentDataVisualization {
                ...ContentfulComponentDataVisualizationFragment
              }
              ... on ContentfulComponentDownload {
                ...ContentfulComponentDownloadFragment
              }
              ... on ContentfulComponentFlexible {
                ...ContentfulComponentFlexibleFragment
              }
              ... on ContentfulComponentList {
                ...ContentfulComponentListFragment
              }
              ... on ContentfulComponentMediaCopy {
                ...ContentfulComponentMediaCopyFragment
              }
              ... on ContentfulComponentMediaSet {
                ...ContentfulComponentMediaSetFragment
              }
              ... on ContentfulComponentTextBlock {
                ...ContentfulComponentTextBlockFragment
              }
              ... on ContentfulComponentComponentSet {
                # can't expand fragment since it has TopicArticle within it
                # ...ContentfulComponentComponentSetFragment
                id
                contentful_id
                name
                __typename
                variant
                gridType
                showDivider
                title
                subtitle
                footnote {
                  raw
                }
                appearance {
                  ...ContentfulDesignAppearanceFragment
                }
                components {
                  ... on ContentfulComponentCalloutCta {
                    ...ContentfulComponentCalloutCtaFragment
                  }
                  ... on ContentfulComponentDownload {
                    ...ContentfulComponentDownloadFragment
                  }
                  ... on ContentfulComponentDataVisualization {
                    ...ContentfulComponentDataVisualizationFragment
                  }
                  ... on ContentfulComponentFlexible {
                    ...ContentfulComponentFlexibleFragment
                  }
                  ... on ContentfulComponentMediaCopy {
                    ...ContentfulComponentMediaCopyFragment
                  }
                  ... on ContentfulComponentTextBlock {
                    ...ContentfulComponentTextBlockFragment
                  }
                  ... on ContentfulComponentComponentSet {
                    id
                    __typename
                    variant
                    showDivider
                    appearance {
                      ...ContentfulDesignAppearanceFragment
                    }
                    components {
                      ... on ContentfulComponentCalloutCta {
                        ...ContentfulComponentCalloutCtaFragment
                      }
                      ... on ContentfulComponentDownload {
                        ...ContentfulComponentDownloadFragment
                      }
                      ... on ContentfulComponentDataVisualization {
                        ...ContentfulComponentDataVisualizationFragment
                      }
                      ... on ContentfulComponentMediaCopy {
                        ...ContentfulComponentMediaCopyFragment
                      }
                      ... on ContentfulComponentTextBlock {
                        ...ContentfulComponentTextBlockFragment
                      }
                    }
                  }
                }
              }
            }
          }
          footnote {
            raw
            references {
              ... on ContentfulComponentDataVisualization {
                ...ContentfulComponentDataVisualizationFragment
              }
            }
          }
        }
      }
      website {
        ...WebsiteFragment
      }
      pageHeader {
        ...ContentfulComponentSiteHeaderFragment
      }
      pageFooter {
        ...ContentfulComponentSiteFooterFragment
      }
    }
  }
`;

const Page: React.FC<PageProps<ArticleTemplateQuery, PageContext>> = (
  props,
) => {
  const { data, location } = props;
  const { contentfulComposePage } = data;
  const { locale } = useLocalization();
  const { site } = useGlobalState();

  const topicArticle =
    contentfulComposePage?.content as unknown as ContentfulTopicArticle;

  const mobileMenuPaddingClasses = 'pt-m4 lg:pt-0';

  const relatedPage = topicArticle?.category?.relatedPage;
  const backToString = getTranslation('BackTo', locale);
  const byString = getTranslation('By', locale);

  const cookiePreferences = contentfulComposePage?.website?.cookiePreferences;

  const pageHeader =
    (contentfulComposePage?.pageHeader as unknown as ContentfulComponentSiteHeader) ??
    (contentfulComposePage?.website
      ?.header as unknown as ContentfulComponentSiteHeader);

  const pageFooter =
    (contentfulComposePage?.pageFooter as unknown as ContentfulComponentSiteFooter) ??
    (contentfulComposePage?.website
      ?.footer as unknown as ContentfulComponentSiteFooter);

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={contentfulComposePage?.seo} location={location} />
      <Header
        data={pageHeader}
        location={location}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        mobileLogo={contentfulComposePage?.website?.siteAssets?.mobileLogo}
      />
      <main id="main" className={`${mobileMenuPaddingClasses}`}>
        <div className="mt-m3 pb-m4">
          <div className="container">
            {relatedPage && (
              <Link
                language={locale}
                link={{ page: relatedPage }}
                className="mb-s4 inline-block"
              >
                <Typography
                  as="div"
                  variant="footerBody3"
                  className="inline-flex items-center"
                >
                  <ChevronLeft className="text-secondary" />
                  {backToString} {relatedPage.title}
                </Typography>
              </Link>
            )}
            {topicArticle?.category?.title && (
              <Typography
                as="div"
                variant="tag"
                className="uppercase text-secondary mb-s1"
              >
                {topicArticle?.category?.title}
              </Typography>
            )}
            {topicArticle?.title && (
              <Typography as="h2" variant="h2" className="uppercase m-0 mb-s1">
                {topicArticle?.title}
              </Typography>
            )}
            {topicArticle?.author && (
              <Typography as="div" variant="body" className="font-medium mb-s1">
                {topicArticle.author.compose__page?.[0] ? (
                  <>
                    {byString}
                    {` `}
                    <Link
                      language={locale}
                      link={{ page: topicArticle.author.compose__page?.[0] }}
                    >
                      {topicArticle?.author.firstName}{' '}
                      {topicArticle?.author.lastName}
                    </Link>
                  </>
                ) : (
                  <>
                    {byString} {topicArticle?.author.firstName}{' '}
                    {topicArticle?.author.lastName}
                  </>
                )}
              </Typography>
            )}
            {topicArticle?.byLine && (
              <Typography as="div" variant="body" className="font-medium mb-s1">
                {topicArticle?.byLine}
              </Typography>
            )}
            {topicArticle?.publicationDate && (
              <Typography as="div" variant="date" className="text-greyText">
                {formatLocalizedDate(
                  getDateWithLocaleOffset(topicArticle?.publicationDate),
                  locale,
                  {
                    month: 'long',
                  },
                )}
              </Typography>
            )}
          </div>
          <div className="container">
            {topicArticle?.soundCloudUrl && (
              <div className="mt-m3">
                <iframe
                  width="100%"
                  height="166"
                  scrolling="no"
                  allow="autoplay"
                  src={`https://w.soundcloud.com/player/?url=${topicArticle.soundCloudUrl}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
                ></iframe>
              </div>
            )}
            {topicArticle?.body && (
              <div className="mt-m4">
                {renderContentfulRichText(
                  topicArticle?.body as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                  articleContentfulRichTextOptions,
                )}
              </div>
            )}
            {topicArticle?.footnote && (
              <>
                <Divider fullWidth className="mt-s3" />
                <div className="mt-s3 break-words md:break-normal">
                  {renderContentfulRichText(
                    topicArticle?.footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                    articleFootnoteContentfulRichTextOptions,
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <Footer
        data={pageFooter}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        socialLinks={contentfulComposePage?.website?.siteAssets?.socialLinks}
      />

      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default Page;
