/* eslint-disable react/display-name */
import React from 'react';
import { navigate } from 'gatsby';
import { Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';
import {
  ContentfulTopicBio,
  ContentfulTopicCategory,
} from '../../graphql-types';
import ContentfulImage from './ContentfulImage';
import Divider from './Divider';
import Typography from './Typography';
import { useLocalization } from 'gatsby-theme-i18n';
import { getTranslation } from '../services/translation.service';
import Link from './Link';
import { useGlobalState } from '../hooks/useGlobalState';

enum SORT_MEMBERS_BY_OPTION {
  FIRST_NAME = 'first-name',
  LAST_NAME = 'last-name',
}

const StyledTabs = styled(Tabs)`
  position: relative;

  .MuiTabs-scrollButtons {
    width: initial;

    &:first-child {
      height: 100%;
      position: absolute;
      left: 0;
      opacity: 1;
      z-index: 1;
    }

    &.MuiTabs-scrollButtonsDesktop {
      display: flex;

      &.Mui-disabled:first-child {
        visibility: hidden;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.md}) {
    .MuiTabs-flexContainer {
      flex-wrap: wrap;
    }
    .MuiTabs-indicator {
      display: none;
    }
  }
`;
const StyledTab = styled(Tab)`
  @media (min-width: ${(props) => props.theme.breakpoint.md}) {
    &.MuiTab-root {
      padding: 0px;
      max-width: none;
      max-height: none;
      min-width: auto;
      min-height: auto;
      ${(props) => props.theme.typography.body}
      text-transform: none;
      text-align: left;
      letter-spacing: normal;
      width: 30%;

      &.Mui-selected {
        font-weight: ${(props) => props.theme.fontWeight.medium};
      }

      .MuiTab-wrapper {
        justify-content: start;
        align-items: start;
      }
    }
  }
`;
interface TeamsProps {
  className?: string;
  columns?: number;
  showFilters?: boolean;
  allFilterLabel?: string;
  subtitle?: string;
  teams?: ContentfulTopicCategory[];
  teamMembers?: ContentfulTopicCategory[];
  sortMembersBy?: string;
  showPositionTitle?: boolean;
}
const Teams: React.FC<TeamsProps> = (props) => {
  const {
    className,
    columns,
    showFilters,
    allFilterLabel,
    subtitle,
    teams,
    teamMembers,
    sortMembersBy,
    showPositionTitle,
  } = props;
  const { locale } = useLocalization();
  const [value, setValue] = React.useState(0);
  const [allTeamBios, setAllTeamBios] = React.useState([]);
  const [tabTeams, setTabTeams] = React.useState([]);
  const { location } = useGlobalState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (teamMembers) {
    React.useEffect(() => {
      setAllTeamBios(teamMembers);
    }, []);
  } else {
    React.useEffect(() => {
      // map and filter for all bios in teams
      const allBios = teams
        // get the bios from each team
        ?.map((team) => team.topic__bio)
        // remove empty arrays (some teams might not have bios)
        .filter((bio) => bio)
        // flatten
        .flat()
        // remove bios appearing in multiple teams
        .filter(
          (bio, index, array) =>
            array.findIndex((t) => t.id === bio.id) === index,
        );
      setAllTeamBios(allBios);

      // create tabTeams array for showing all filters + Show All section
      const showAllTeam = {
        id: 'show-all',
        title: allFilterLabel || getTranslation('ShowAll', locale),
        topic__bio: allBios,
      };
      setTabTeams([...teams, showAllTeam]);
    }, []);
  }

  // TODO: Remove when we are confident most urls are updated to new
  // bio pages.
  // look for bio= parameter in url, and redirect to new page if possible
  React.useEffect(() => {
    if (location?.search && allTeamBios.length > 0) {
      const params = new URLSearchParams(location?.search);
      const bioParameter = params.get('bio');

      // find bio
      const foundBio = allTeamBios.find((bio) => {
        const bioSlug = `${bio.firstName?.toLowerCase()}-${bio.lastName?.toLowerCase()}`;
        return bioSlug === bioParameter;
      });

      if (foundBio?.compose__page?.[0]?.slug) {
        navigate(`/${foundBio?.compose__page?.[0]?.slug}`);
      }
    }
  }, [location, allTeamBios]);

  const getBiosGrid = (bios: ContentfulTopicBio[]) => {
    // sort bios
    if (!teamMembers) {
      bios.sort((a, b) => {
        if (sortMembersBy === SORT_MEMBERS_BY_OPTION.FIRST_NAME) {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        } else if (sortMembersBy === SORT_MEMBERS_BY_OPTION.LAST_NAME) {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        }

        // don't sort!
        return 0;
      });
    }

    const gridColumns =
      columns === 1
        ? 'md:grid-cols-1'
        : columns === 2
        ? 'md:grid-cols-2'
        : columns === 3
        ? 'md:grid-cols-3'
        : columns === 4
        ? 'md:grid-cols-4'
        : 'md:grid-cols-5';
    return (
      <div
        className={`grid grid-cols-2 gap-y-s4 gap-x-s2 sm:grid-cols-3 ${gridColumns} md:gap-y-m2 md:gap-x-s3`}
      >
        {bios?.map((bio) => {
          const page = bio?.compose__page?.[0];
          return (
            <div key={bio.id} className="col-span-1">
              <Link
                language={locale}
                link={{ page: page }}
                className={`flex flex-col items-center justify-start pb-3 ${
                  locale !== 'cn' ? 'hover:underline' : 'cursor-default'
                }`}
              >
                {bio.image && (
                  <ContentfulImage
                    image={bio.image}
                    alt={
                      bio.image?.description ||
                      `Picture of ${bio.firstName} ${bio.lastName}`
                    }
                    objectPosition="center top"
                    className="max-w-[130px] h-[160px] md:max-w-[170px] md:h-[205px]"
                  />
                )}
                <Typography
                  as="div"
                  variant="body"
                  className="mt-s2 font-medium text-center w-full break-words"
                >
                  {bio.firstName} {bio.lastName}
                </Typography>
                {showPositionTitle && (
                  <Typography
                    as="div"
                    className="font-smaller leading-none text-center w-full break-words"
                  >
                    {bio.positionTitle || bio.position}
                  </Typography>
                )}
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  let teamsElement = showFilters ? (
    // build out material-ui tabs for filtering each team
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="Teams Tabs"
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabTeams.map((team, index) => {
          return (
            <StyledTab
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
              key={team.id}
              label={team.title}
              disableRipple
              wrapped={true}
              className="focus:ring-2 focus:underline hover:underline"
            />
          );
        })}
      </StyledTabs>
      <Divider
        fullWidth={true}
        color={'black'}
        className="hidden md:block mt-s2"
      />
      {tabTeams.map((team, index) => {
        return (
          <div
            key={team.id}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            className="mt-m2"
          >
            {getBiosGrid(team.topic__bio)}
          </div>
        );
      })}
    </>
  ) : (
    // Simple display of all team members
    <>{allTeamBios && allTeamBios.length > 0 && getBiosGrid(allTeamBios)}</>
  );

  // simplified teams for CN home
  if (locale === 'cn') {
    teamsElement = <>{getBiosGrid(allTeamBios)}</>;
  }

  return (
    <section className={`container ${className ?? ''}`}>{teamsElement}</section>
  );
};

export default Teams;
