import React from 'react';
import Typography from './Typography';
import styled, { ThemeContext } from 'styled-components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {
  ContentfulComponentDataVisualizationFootnote,
  ContentfulTopicTabularDataSet,
} from '../../graphql-types';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { getRowDataFromDataFile } from '../utils/parseTabularDataSet';
import {
  getColumnAlignment,
  getColumnStyles,
  getDefinitionsList,
  getDisplayValue,
  getHorizontalScrollIndicatorState,
  getTotalsRow,
  HorizontalScrollIndicator,
} from '../services/table.service';
import { useLocalization } from 'gatsby-theme-i18n';
import { getTranslation } from '../services/translation.service';
import { useGlobalState } from '../hooks/useGlobalState';

const TableWrapper = styled.div`
  position: relative;
`;
interface DataVisualizationTableProps {
  className?: string;
  variant?: string;
  title?: string;
  subheading?: { subheading: string };
  showAsAtDate?: boolean;
  footnote?: ContentfulComponentDataVisualizationFootnote;
  dataSets?: ContentfulTopicTabularDataSet[];
  headlineOrientation?: 'stacked' | 'split';
  overrideTableFontSize: boolean;
}

const DataVisualizationTable: React.FC<DataVisualizationTableProps> = (
  props,
) => {
  const {
    title,
    subheading,
    showAsAtDate,
    dataSets,
    footnote,
    headlineOrientation,
    overrideTableFontSize,
  } = props;
  const { locale } = useLocalization();

  const footnoteRichTextOptions: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography
          as="small"
          variant="body"
          className="block mt-s2 text-xs leading-relaxed"
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography
          as="span"
          variant="body"
          className="block mt-s2 leading-relaxed"
        >
          {children}
        </Typography>
      ),
    },
  };

  // // shows a gradient when the table data overflows horizontally
  const ScrollableContainerRef = React.createRef<HTMLDivElement>();
  const [showHorizontalIndicator] = getHorizontalScrollIndicatorState(
    ScrollableContainerRef,
  );

  const [tableMinHeight, setTableMinHeight] = React.useState<null | number>(
    null,
  );
  const [tableMaxHeight, setTableMaxHeight] = React.useState<null | number>(
    null,
  );
  const [tableFixedHeaders, setTableFixedHeaders] = React.useState<
    null | boolean
  >(null);

  const {
    fontSize: { body },
  } = React.useContext(ThemeContext);

  // "As at" formatting
  const asAtText = getTranslation('AsAt', locale);
  const { asAtDate } = useGlobalState();
  const formattedAsAtDate = new Date(asAtDate).toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });

  const columnData = dataSets?.[0]?.columns;
  const displayTotal = dataSets?.[0]?.displayTotal;

  let definitionIndex = 1;
  const definitions =
    columnData &&
    columnData
      .concat(dataSets?.[0]?.rows?.flatMap((row) => row.values))
      .filter((item) => {
        return item?.additionalInfo?.copy; // Adding a check for copy for previews
      })
      .map((cell) => cell?.additionalInfo?.copy);

  const setRowDataFromDataset = async (dataSet) => {
    const result = await getRowDataFromDataFile(dataSet);
    setRowData(result);
  };
  const [rowData, setRowData] = React.useState(dataSets?.[0]?.rows);

  React.useEffect(() => {
    // assumption is that Table can only use 1 data set anyways, so we take
    // only the first one
    if (dataSets?.[0]?.dataFile) {
      setRowDataFromDataset(dataSets?.[0]);
    }
    setTableMinHeight(dataSets?.[0]?.settingMinHeight);
    setTableMaxHeight(dataSets?.[0]?.settingMaxHeight);
    setTableFixedHeaders(dataSets?.[0]?.settingFixedHeaders);
  }, [dataSets]);

  const tableFontSize = overrideTableFontSize ? body : '';

  return (
    <div className="container">
      <div className="lg:grid gap-s3 grid-cols-10">
        {(title || subheading) && (
          <div
            className={`mb-s4 ${
              headlineOrientation === 'split'
                ? 'lg:col-span-4'
                : 'lg:col-span-12'
            }`}
          >
            <div>{title && <Typography variant="h1">{title}</Typography>}</div>
            <div>
              {subheading && (
                <Typography as="div" variant="date" className="mt-s1">
                  {subheading.subheading}
                </Typography>
              )}
              {showAsAtDate && (
                <Typography as="div" variant="date" className="mt-s1 mb-s2">
                  {`${asAtText} ${formattedAsAtDate}`}
                </Typography>
              )}
            </div>
          </div>
        )}
        <div
          className={
            headlineOrientation === 'split' ? 'lg:col-span-6' : 'lg:col-span-12'
          }
        >
          <TableWrapper>
            {showHorizontalIndicator && <HorizontalScrollIndicator />}

            <TableContainer
              ref={ScrollableContainerRef}
              style={{
                minHeight: tableMinHeight ? `${tableMinHeight}px` : 'auto',
                maxHeight: tableMaxHeight ? `${tableMaxHeight}px` : 'auto',
              }}
              component="div"
            >
              <Table
                stickyHeader={tableFixedHeaders ? true : false}
                aria-label={title}
              >
                <TableHead>
                  <TableRow>
                    {columnData &&
                      columnData.length &&
                      columnData.map((column, index) => {
                        const columnStyle = getColumnStyles(
                          column,
                          tableFontSize,
                        );
                        const columnAlign = getColumnAlignment(column);

                        return (
                          <TableCell
                            style={columnStyle}
                            align={columnAlign}
                            key={`column-${index}`}
                          >
                            {column.additionalInfo && (
                              <sup>{definitionIndex++}</sup>
                            )}
                            {column.title}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData &&
                    rowData.length &&
                    rowData.map((row, index) => (
                      <TableRow key={`row-${index}`}>
                        {/* we need to map over the amount of columns to ensure we 
                        have enough table cells to fill row */}
                        {columnData &&
                          columnData.length &&
                          columnData.map((column, index) => {
                            const columnStyle = getColumnStyles(
                              column,
                              tableFontSize,
                            );
                            const columnAlign = getColumnAlignment(column);

                            const cell = row.values?.[index];
                            const columnType = columnData[index]?.type;
                            let displayValue = '-';
                            if (cell && cell.value) {
                              displayValue = getDisplayValue(
                                cell.value,
                                columnType,
                                locale,
                                {
                                  decimalPlaces:
                                    columnData[index]?.settingDecimalPlaces,
                                },
                              );
                            }

                            return (
                              <TableCell
                                style={columnStyle}
                                align={columnAlign}
                                key={`row-${index}-cell-${index}`}
                              >
                                {cell?.additionalInfo && (
                                  <sup>{definitionIndex++}</sup>
                                )}
                                {cell?.valueLink ? (
                                  <a
                                    href={cell.valueLink}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    {displayValue}
                                  </a>
                                ) : (
                                  displayValue
                                )}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    ))}

                  {displayTotal && getTotalsRow(columnData, rowData, locale)}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapper>
          {footnote &&
            renderContentfulRichText(
              footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
              footnoteRichTextOptions,
            )}
        </div>
      </div>

      {getDefinitionsList(definitions, locale)}
    </div>
  );
};

export default DataVisualizationTable;
