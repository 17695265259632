/* eslint-disable react/display-name */
import React from 'react';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { replaceContentfulLineBreaks } from './replaceContentfulLineBreaks';
import ComponentSelector from '../components/ComponentSelector';
import { ContentfulAsset } from '../../graphql-types';
import Typography from '../components/Typography';
import Divider from '../components/Divider';
import ContentfulImage from '../components/ContentfulImage';
import { replaceContentfulHtml } from './replaceContentfulHtml';
import styled from 'styled-components';
import { replaceContentfulNonBreakingSpace } from './replaceContentfulNonBreakingSpace';
import Link from '../components/Link';

const RichTextContainer = styled.div`
  a {
    text-decoration: underline;
  }
`;

// nested <ul> styles
const StyledUl = styled.ul`
  list-style-type: disc !important;
  & ul {
    margin-top: 12px !important;
    list-style-type: circle !important;
    & ul {
      list-style-type: square !important;
    }
  }
`;
// nested <ol> styles
const StyledOl = styled.ol`
  list-style-type: decimal !important;
  & ol {
    margin-top: 12px !important;
    list-style-type: lower-latin !important;
    & ol {
      list-style-type: lower-roman !important;
    }
  }
`;

const defaultOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Contentful seems to add empty paragraphs at the end of rich-text
      // cf: https://github.com/contentful/rich-text/issues/101
      const isEmptyChildren = children?.toString()?.trim() === '';
      if (isEmptyChildren) {
        return null;
      }

      return (
        <Typography as="p" variant="body" className="mt-s4 first:mt-0">
          {children}
        </Typography>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <StyledUl className="list-disc pl-5 mt-s3">{children}</StyledUl>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <StyledOl className="list-decimal pl-5 mt-s3">{children}</StyledOl>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography as="h1" variant="h1" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography as="h2" variant="h2" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography as="h3" variant="h3" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography as="h4" variant="h4" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography as="h5" variant="h4" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography as="h6" variant="h4" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HR]: () => <Divider fullWidth className="mt-s3" />,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <ComponentSelector contentfulComponent={node.data.target} />
        )
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      switch (node.data?.target.__typename) {
        case 'ContentfulAsset': {
          const contentfulAsset = node.data?.target as ContentfulAsset;
          return (
            <ContentfulImage
              image={contentfulAsset}
              alt={contentfulAsset?.gatsbyImageData?.description || ''}
            />
          );
        }
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <ComponentSelector contentfulComponent={node.data.target} />
        )
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      return (
        <a
          href={`https://${node.data?.target?.file?.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content?.[0]?.value}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const langCode =
        node.data?.target?.node_locale === 'en-CA'
          ? 'en'
          : node.data?.target?.node_locale === 'fr-CA'
          ? 'fr'
          : 'cn';
      return (
        <Link language={langCode} link={{ page: node.data?.target }}>
          {children}
        </Link>
      );
    },
  },
  renderText: (text) => {
    let value = replaceContentfulLineBreaks(text);
    value = replaceContentfulNonBreakingSpace(value);
    value = replaceContentfulHtml(value);
    return value;
  },
};

/**
 * renderRichText
 * Helper function that renders Contentful rich text fields as React elements.
 *
 * @param richTextField Contentful rich-text field - A contentful rich text `raw` rich text field.
 * @param options Optional. Contentful rich-text options object.
 * @return React.ReactNode - The corresponding React elements for the rich text.
 */
export const renderContentfulRichText = (
  richTextField: RenderRichTextData<ContentfulRichTextGatsbyReference>,
  options?: Options,
  className?: string,
): React.ReactNode => {
  // return early if no rich text
  if (!richTextField) {
    return <></>;
  }

  const combinedContentfulOptions = {
    ...defaultOptions,
  };

  // combine default options with passed options
  if (options && options.renderMark) {
    combinedContentfulOptions.renderMark = {
      ...defaultOptions.renderMark,
      ...options.renderMark,
    };
  }
  if (options && options.renderNode) {
    combinedContentfulOptions.renderNode = {
      ...defaultOptions.renderNode,
      ...options.renderNode,
    };
  }
  if (options && options.renderText) {
    combinedContentfulOptions.renderText = options.renderText;
  }

  return (
    <RichTextContainer className={className ?? ''}>
      {renderRichText(richTextField, combinedContentfulOptions)}
    </RichTextContainer>
  );
};
