import React from 'react';
import styled from 'styled-components';
import ComponentSelector from './ComponentSelector';
import Typography from './Typography';
import theme from '../themes/edgepoint';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const GRID_TYPE_OPTIONS = {
  EVEN: 'Even',
  ONE_THIRD: '1/3',
  TWO_THIRDS: '2/3',
};

const ComponentSetWrapper = styled.div`
  // for overriding component containers within the component set,
  // as the component set will determine the container
  .container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    & > div[class*='Typography']:first-child {
      text-align: center;
    }
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: ${theme.spacing.s4};
`;

const footnoteRichTextOptions: Options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography
        as="small"
        variant="body"
        className="block mt-s2 text-xs leading-relaxed"
      >
        {children}
      </Typography>
    ),
  },
};

// Create CSS for visual divider as determined by CMS
const Container = styled.div<{
  variant: string;
  showDivider: boolean;
}>`
  ${(props) => {
    let css = ``;
    if (props.variant === 'horizontal' && props.showDivider) {
      if (props.showDivider) {
        css += `
        @media (min-width: ${props.theme.breakpoint.lg}) {
          & > div:not(:first-of-type) {
            border-left: 1px solid ${props.theme.color.secondary};
          }
        }
        `;
      }
    } else if (props.showDivider) {
      css += `
        & > div:not(:first-of-type) {
          border-top: 1px solid ${props.theme.color.secondary};
        }
        `;
    }
    return css;
  }}
`;

interface ComponentSetProps {
  className?: string;
  components?: any[];
  showDivider?: boolean;
  variant?: string;
  gridType?: string;
  isChildComponent?: boolean;
  title?: string;
  subtitle?: string;
  footnote?: any;
}
const ComponentSet: React.FC<ComponentSetProps> = (props) => {
  const {
    className,
    components,
    showDivider,
    variant,
    gridType,
    isChildComponent,
    title,
    subtitle,
    footnote,
  } = props;

  // Determine grid spacing if horizontal layout option
  let containerClasses = '';
  // If a component in set is a Data Visualization, force it to be 'even' spacing
  const containsDataVis: boolean = components.some(
    (e) => e.__typename === 'ContentfulComponentDataVisualization',
  );

  if (variant === 'horizontal' && containsDataVis) {
    containerClasses = 'lg:grid lg:grid-flow-col lg:gap-x-s3 lg:auto-cols-fr';
  } else if (variant === 'horizontal') {
    containerClasses = 'lg:grid lg:grid-flow-col lg:gap-x-s3';
    switch (gridType) {
      case GRID_TYPE_OPTIONS.ONE_THIRD:
      case GRID_TYPE_OPTIONS.TWO_THIRDS: {
        containerClasses += ' lg:grid-cols-3';
        break;
      }
      case GRID_TYPE_OPTIONS.EVEN:
      default: {
        containerClasses += ' lg:auto-cols-fr';
      }
    }
  } else {
    containerClasses = 'flex flex-col';
  }

  return (
    <div>
      {/* Optional title and subtitle */}
      {(title || subtitle) && (
        <TitleWrapper>
          {title && (
            <Typography
              as="h2"
              variant="h2"
              className="uppercase text-center mb-s1"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography as="div" variant="date" className="mb-s1 text-center">
              {subtitle}
            </Typography>
          )}
        </TitleWrapper>
      )}
      <Container
        variant={variant}
        showDivider={showDivider}
        className={`${className} ${containerClasses} `}
      >
        {/* Determine grid item span - a set with a Data Visualization will force even spacing */}
        {components?.map((contentfulComponent, index) => {
          let gridItemClass = '';
          if (!containsDataVis) {
            if (gridType === '1/3' && (index + 1) % 2 == 0) {
              gridItemClass = 'col-span-2';
            }
            if (gridType === '2/3' && index === 0) {
              gridItemClass = 'col-span-2';
            }
          }
          return (
            <ComponentSetWrapper
              key={`component-set-${index}`}
              className={gridItemClass}
            >
              <ComponentSelector
                contentfulComponent={contentfulComponent}
                isChildComponent={isChildComponent}
              />
            </ComponentSetWrapper>
          );
        })}
      </Container>
      {/* optional footnote to span full component set */}
      {footnote && (
        <Typography
          as="small"
          variant="body"
          className="block mt-s2 text-xs leading-relaxed -mx-s3"
        >
          {renderContentfulRichText(
            footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            footnoteRichTextOptions,
          )}
        </Typography>
      )}
    </div>
  );
};

export default ComponentSet;
