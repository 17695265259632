import React from 'react';
import styled from 'styled-components';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import Typography from './Typography';
import InfoTooltip from './InfoTooltip';
import { WEBSITE } from '../types/website.enum';
import { useGlobalState } from '../hooks/useGlobalState';
import { STAT_TYPE } from '../types/statType.enum';
import {
  ContentfulComponentPortfolioInformationFootnote,
  ContentfulTopicStat,
} from '../../graphql-types';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import { formatLocalizedCurrency } from '../services/localization.service';
import { useLocalization } from 'gatsby-theme-i18n';
import { getTranslation } from '../services/translation.service';

const GridContainer = styled.div<{ columns?: number }>`
  display: grid;
  margin: 48px auto 0;
  gap: 48px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);

  @media (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 440px) {
    grid-template-columns: 1fr;
  }
`;

const RiskIndicator = styled.div`
  width: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.greyText};

  &.riskLevel {
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.color.orange2};
  }
`;

interface PortfolioInformationProps {
  title: string;
  subheading: string;
  showAsAtDate?: boolean;
  statsColumns: number;
  stats: ContentfulTopicStat[];
  riskProfile: number;
  footnote: ContentfulComponentPortfolioInformationFootnote;
  className?: string;
}

const PortfolioInformation: React.FC<PortfolioInformationProps> = (props) => {
  const { site } = useGlobalState();
  const {
    title,
    subheading,
    showAsAtDate,
    statsColumns,
    stats,
    riskProfile,
    footnote,
    className,
  } = props;

  const { locale } = useLocalization();

  const footnoteRichTextOptions: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography as="p" variant="footerBody3" className="mt-8">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography as="p" variant="body" className="mt-8">
          {children}
        </Typography>
      ),
    },
  };

  // "As at" formatting
  const asAtText = getTranslation('AsAt', locale);
  const { asAtDate } = useGlobalState();
  const formattedAsAtDate = new Date(asAtDate).toLocaleDateString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });

  return (
    <div className={`container ${className ?? ''}`}>
      {site === WEBSITE.CYMBRIA ? (
        <>
          <Typography as="h2" variant="h2" className="text-center">
            {title}
          </Typography>
          {subheading && (
            <Typography as="h3" variant="date" className="text-center">
              {subheading}
            </Typography>
          )}
        </>
      ) : (
        <div className="flex justify-between items-center flex-wrap gap-8">
          <div>
            <Typography as="h2" variant="h2" className="uppercase">
              {title}
            </Typography>
            {subheading && (
              <Typography as="h3" variant="date">
                {subheading}
              </Typography>
            )}
            {showAsAtDate && (
              <Typography as="div" variant="date" className="mt-s1">
                {`${asAtText} ${formattedAsAtDate}`}
              </Typography>
            )}
          </div>
          {riskProfile && (
            <div className="flex justify-end items-center">
              <Typography variant="tag" className="uppercase font-bold">
                {getTranslation('RiskProfile', locale)} –{' '}
              </Typography>
              <Typography variant="footerBody2" className="ml-s1">
                {getTranslation('Low', locale)}
              </Typography>
              <div className="flex justify-between items-center">
                {[1, 2, 3, 4, 5].map((riskLevel) => (
                  <RiskIndicator
                    key={`risk-level-${riskLevel}`}
                    className={riskLevel === riskProfile ? 'riskLevel' : null}
                  />
                ))}
              </div>
              <Typography variant="footerBody2">
                {getTranslation('High', locale)}
              </Typography>
            </div>
          )}
        </div>
      )}
      <GridContainer columns={statsColumns}>
        {stats?.map((stat, index) => {
          // format title to type
          let title = stat.title;
          switch (stat.type) {
            case STAT_TYPE.DOLLAR: {
              try {
                title = formatLocalizedCurrency(parseFloat(title), locale, 0);
              } catch (e) {
                // can't do much here if parseFloat doesn't work
              }
              break;
            }
            case STAT_TYPE.NUMBER: {
              // TODO: Any specific parsing we need to do?
              title = `${title}`;
              break;
            }
            case STAT_TYPE.PERCENT: {
              title = `${title}%`;
              break;
            }
            case STAT_TYPE.TEXT: {
              // noop
              break;
            }
          }
          return (
            <div
              key={`stat-${stat.id}-${index}`}
              className="text-center min-width-min"
            >
              <Typography as="div" variant="h4">
                <span
                  className={site === WEBSITE.EDGEPOINT ? 'text-secondary' : ''}
                >
                  {title}
                </span>
                {stat.additionalInfo?.copy && (
                  <InfoTooltip copy={stat?.additionalInfo?.copy} />
                )}
              </Typography>
              {stat.subheading?.subheading && (
                <Typography as="div" variant="body">
                  {stat.subheading.subheading}
                </Typography>
              )}
            </div>
          );
        })}
      </GridContainer>
      {footnote &&
        renderContentfulRichText(
          footnote as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
          footnoteRichTextOptions,
        )}
    </div>
  );
};

export default PortfolioInformation;
